import * as React from 'react'
import Box from '@material-ui/core/Box'
import Collapse from '@material-ui/core/Collapse'
import Typography from '@material-ui/core/Typography'
import download from 'downloadjs'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import {
    Col,
    Dropdown,
    DropdownButton,
    Nav,
    OverlayTrigger,
    Popover,
    Row,
} from 'react-bootstrap'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Input from '@material-ui/core/Input'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import DatePicker from 'react-datepicker'
import ButtonComponent from 'Components/Button/Button'
import { useEffect, useState } from 'react'
import {
    getProjectionList,
    getProjectionListCsv,
    lockProjection,
} from 'Services/ResourceService/ResourceService'

interface State {
    isLocked?: boolean
    projectionDropDownItems?: any
}
function Row2(props: { row; monthSingle: any }) {
    const { monthSingle } = props
    const { row } = props
    const [open, setOpen] = React.useState(false)

    return (
        <React.Fragment>
            <TableRow>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? (
                            <KeyboardArrowUpIcon />
                        ) : (
                            <KeyboardArrowDownIcon />
                        )}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.projectName}
                </TableCell>
                <TableCell>{row.projectOwner}</TableCell>
                <TableCell>{row.noidaTotalHoursMonth1}</TableCell>
                <TableCell>{row.yvrTotalHoursMonth1}</TableCell>
                <TableCell>{row.noidaTotalHoursMonth2}</TableCell>

                <TableCell>{row.yvrTotalHoursMonth2}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={6}
                >
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography
                                variant="h6"
                                gutterBottom
                                component="div"
                            ></Typography>

                            <Table
                                size="small"
                                aria-label="purchases"
                                className="weeks-table"
                            >
                                <TableCell>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell
                                                style={{ fontWeight: 'bold' }}
                                            >
                                                Weeks
                                            </TableCell>
                                            <TableCell
                                                align="right"
                                                style={{ fontWeight: 'bold' }}
                                            >
                                                Noida ({monthSingle.firstMonth})
                                            </TableCell>
                                            <TableCell
                                                align="right"
                                                style={{ fontWeight: 'bold' }}
                                            >
                                                YVR ({monthSingle.firstMonth})
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {row.projectionDetailMonth1.map(
                                            (historyRow) => (
                                                <TableRow key={historyRow.week}>
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                    >
                                                        {historyRow.week}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {
                                                            historyRow.projectionLocationNoidaHours
                                                        }
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {
                                                            historyRow.projectionLocationYVRHours
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        )}
                                    </TableBody>
                                </TableCell>
                                <TableCell>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell
                                                style={{ fontWeight: 'bold' }}
                                            >
                                                Weeks
                                            </TableCell>
                                            <TableCell
                                                align="right"
                                                style={{ fontWeight: 'bold' }}
                                            >
                                                Noida ({monthSingle.secondmonth}
                                                )
                                            </TableCell>
                                            <TableCell
                                                align="right"
                                                style={{ fontWeight: 'bold' }}
                                            >
                                                YVR ({monthSingle.secondmonth})
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {row.projectionDetailMonth2.map(
                                            (historyRow) => (
                                                <TableRow key={historyRow.week}>
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                    >
                                                        {historyRow.week}
                                                    </TableCell>

                                                    <TableCell align="right">
                                                        {
                                                            historyRow.projectionLocationNoidaHours
                                                        }
                                                    </TableCell>

                                                    <TableCell align="right">
                                                        {
                                                            historyRow.projectionLocationYVRHours
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        )}
                                    </TableBody>
                                </TableCell>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    )
}

export default function ProjectionAdmin(state: State) {
    state = {
        isLocked: false,
        projectionDropDownItems: {
            projectId: {
                id: 0,
                name: 'Project',
            },
            projectOwnerId: {
                id: 0,
                name: 'PO',
            },
            year: {
                id: 0,
                name: 'Year',
            },
            month: {
                id: 0,
                name: 'Month',
            },
        },
    }
    const [monthSingle, setMonthSingle]: any = useState({
        firstMonth: 'Month 1',
        secondmonth: 'Month 2',
    })
    const [startDate, setStartDate] = useState(new Date())
    const [sortStatus, setSortstatus]: any = useState(true)
    const [projectionState, setProjectionState] = useState(state)
    const [years, setYears]: any = useState([])
    const [rows, setRows]: any = useState([])
    const [uiLoader, setUiLoader] = useState(true)
    const [showMsg, setShowMsg] = useState(true)
    const [message, setMessage] = useState('')
    const [loadMessage, setLoadMessage] = useState('')
    const [isLockedButton, setIsLockedButton] = useState(false)
    let monthPairs = [
        { id: '1', name: 'Jan - Feb', firstMonth: 'Jan', secondmonth: 'Feb' },
        { id: '2', name: 'Feb - Mar', firstMonth: 'Feb', secondmonth: 'Mar' },
        { id: '3', name: 'Mar - Apr', firstMonth: 'Mar', secondmonth: 'Apr' },
        { id: '4', name: 'Apr - May', firstMonth: 'Apr', secondmonth: 'May' },
        { id: '5', name: 'May - Jun', firstMonth: 'May', secondmonth: 'Jun' },
        { id: '6', name: 'Jun - Jul', firstMonth: 'Jun', secondmonth: 'Jul' },
        { id: '7', name: 'Jul - Aug', firstMonth: 'Jul', secondmonth: 'Aug' },
        { id: '8', name: 'Aug - Sep', firstMonth: 'Aug', secondmonth: 'Sep' },
        { id: '9', name: 'Sep - Oct', firstMonth: 'Sep', secondmonth: 'Oct' },
        { id: '10', name: 'Oct - Nov', firstMonth: 'Oct', secondmonth: 'Nov' },
        { id: '11', name: 'Nov - Dec', firstMonth: 'Nov', secondmonth: 'Dec' },
        { id: '12', name: 'Dec - Jan', firstMonth: 'Dec', secondmonth: 'Jan' },
    ]

    useEffect(() => {
        let options: any = []
        let prevYear = 2000
        for (let i = 0; i <= 40; i++) {
            options.push((prevYear = prevYear + 1))
        }
        setYears([...years, ...options])
        let currentMonth = new Date().getMonth() + 1
        let monthResult = monthPairs.find((months) => {
            return months.id == currentMonth + ''
        })

        const dropDownValue = {
            ...projectionState,
            projectionDropDownItems: {
                ...projectionState.projectionDropDownItems,
                month: {
                    id: monthResult?.id,
                    name: monthResult?.name,
                },
                year: {
                    id: new Date().getFullYear(),
                    name: new Date().getFullYear(),
                },
            },
        }
        setProjectionState(dropDownValue)
        setMonthSingle({
            ...monthSingle,
            firstMonth: monthResult?.firstMonth,
            secondmonth: monthResult?.secondmonth,
        })
    }, [])
    useEffect(() => {
        setUiLoader(true)
        setLoadMessage('Loading...')
        try {
            getProjectionList(projectionState.projectionDropDownItems)
                .then((response: any) => {
                    if (response?.data) {
                        setRows(response?.data)
                        setIsLockedButton(response?.data[0]?.isLocked)
                        setUiLoader(false)
                    } else {
                        setRows([])
                        setUiLoader(true)
                        setLoadMessage('No Data Found')
                    }
                })
                .catch((error) => console.log('Errorrr'))
        } catch (error) {}
    }, [
        projectionState?.projectionDropDownItems?.year?.id,
        projectionState?.projectionDropDownItems?.month?.id,
    ])
    const setDropDownValue = (type: string, value: any) => {
        if (type === 'projectId') {
            const dropDownValue = {
                ...projectionState,
                projectionDropDownItems: {
                    ...projectionState.projectionDropDownItems,
                    projectId: {
                        id: value?.id,
                        name: value?.name,
                    },
                },
            }
            setProjectionState(dropDownValue)
        } else if (type === 'projectOwnerId') {
            const dropDownValue = {
                ...projectionState,
                projectionDropDownItems: {
                    ...projectionState.projectionDropDownItems,
                    projectOwnerId: {
                        id: value?.pkId,
                        name: value?.firstName,
                    },
                },
            }
            setProjectionState(dropDownValue)
        } else if (type === 'year') {
            const dropDownValue = {
                ...projectionState,
                projectionDropDownItems: {
                    ...projectionState.projectionDropDownItems,
                    year: {
                        id: value,
                        name: value,
                    },
                },
            }
            setProjectionState(dropDownValue)
        } else if (type === 'month') {
            const dropDownValue = {
                ...projectionState,
                projectionDropDownItems: {
                    ...projectionState.projectionDropDownItems,
                    month: {
                        id: value?.id,
                        name: value?.name,
                    },
                },
            }
            setProjectionState(dropDownValue)
            setMonthSingle({
                ...monthSingle,
                firstMonth: value?.firstMonth,
                secondmonth: value?.secondmonth,
            })
        }
    }

    const exportProjection = () => {
        getProjectionListCsv(projectionState.projectionDropDownItems)
            .then((response: any) => response?.data)
            .then((data) =>
                download(
                    data,
                    `${monthSingle?.firstMonth}_${monthSingle?.secondmonth}_Projection.csv`,
                    'text/csv'
                )
            )
            .catch((error) => console.log('Errorrr'))
    }
    const postLockProjection = () => {
        let lockObj = {
            month: projectionState?.projectionDropDownItems?.month?.id,
            year: projectionState?.projectionDropDownItems?.year?.id,
        }
        lockProjection(
            parseInt(projectionState?.projectionDropDownItems?.month?.id),
            parseInt(projectionState?.projectionDropDownItems?.year?.id)
        ).then((response: any) => {
            setMessage('Projection has been locked successfully')
        })
        setTimeout(() => {
            setShowMsg(false)
        }, 5000)
        setMessage('')
        setShowMsg(true)
    }
    return (
        <div>
            <div className="right-holder">
                <Nav.Item id="button-nav-project">
                    <Row>
                        <Col>
                            {
                                <DropdownButton
                                    className={`align-dropdown 
                                   assign-title-center dropdown-font`}
                                    key={'secondary'}
                                    id={`dropdown-variants-secondary`}
                                    variant={'secondary'}
                                    title={
                                        projectionState.projectionDropDownItems
                                            .month.name
                                    }
                                >
                                    {monthPairs?.map((data: any, index) => {
                                        return (
                                            <Dropdown.Item
                                                key={index}
                                                eventKey={`${index + 1}`}
                                                onClick={() =>
                                                    setDropDownValue(
                                                        'month',
                                                        data
                                                    )
                                                }
                                            >
                                                {data?.name}
                                            </Dropdown.Item>
                                        )
                                    })}
                                </DropdownButton>
                            }
                        </Col>
                        <Col>
                            <DropdownButton
                                className={`align-dropdown 
                                        assign-title-center dropdown-font`}
                                key={'secondary'}
                                id={`dropdown-variants-secondary`}
                                variant={'secondary'}
                                title={
                                    projectionState.projectionDropDownItems.year
                                        .name
                                }
                            >
                                {years?.map((data, index) => {
                                    return (
                                        <Dropdown.Item
                                            key={index}
                                            eventKey={`${index + 1}`}
                                            onClick={() =>
                                                setDropDownValue('year', data)
                                            }
                                        >
                                            {data}
                                        </Dropdown.Item>
                                    )
                                })}
                            </DropdownButton>
                        </Col>
                        <Col>
                            {
                                <ButtonComponent
                                    style={{
                                        fontSize: '14px',
                                        // margin: '5px',

                                        padding: '30px',
                                    }}
                                    name={isLockedButton ? 'Locked' : 'Lock'}
                                    onClick={() => postLockProjection()}
                                />
                            }
                        </Col>

                        <Col className="toggle-switch">
                            {
                                <BootstrapSwitchButton
                                    checked={true}
                                    onlabel="Project"
                                    onstyle="danger"
                                    offlabel="PO"
                                    offstyle="success"
                                    style="w-100 mx-3"
                                    size="sm"
                                    onChange={(checked: boolean) => {
                                        setSortstatus(checked)
                                    }}
                                />
                            }
                        </Col>
                        <Col className="export-btn">
                            {
                                <ButtonComponent
                                    style={{
                                        fontSize: '14px',
                                        // margin: '5px',

                                        padding: '30px',
                                    }}
                                    name={'Export'}
                                    onClick={() => exportProjection()}
                                />
                            }
                        </Col>
                    </Row>
                </Nav.Item>
            </div>
            {showMsg && <span style={{ marginLeft: '30px' }}> {message}</span>}
            <Table aria-label="collapsible table">
                <TableHead>
                    <TableRow>
                        <TableCell style={{ fontWeight: 'bold' }}>
                            Weeks
                        </TableCell>
                        <TableCell style={{ fontWeight: 'bold' }}>
                            Project
                        </TableCell>
                        <TableCell style={{ fontWeight: 'bold' }}>PO</TableCell>
                        <TableCell style={{ fontWeight: 'bold' }}>
                            Noida ({monthSingle.firstMonth})
                        </TableCell>
                        <TableCell style={{ fontWeight: 'bold' }}>
                            YVR ({monthSingle.firstMonth})
                        </TableCell>
                        <TableCell style={{ fontWeight: 'bold' }}>
                            Noida ({monthSingle.secondmonth})
                        </TableCell>

                        <TableCell style={{ fontWeight: 'bold' }}>
                            YVR ({monthSingle.secondmonth})
                        </TableCell>
                    </TableRow>
                </TableHead>
                {uiLoader ? (
                    <span className="loader-list">{loadMessage}</span>
                ) : (
                    <TableBody>
                        {sortStatus
                            ? rows
                                  ?.sort((first, second) => {
                                      return first.projectName >
                                          second.projectName
                                          ? 1
                                          : -1
                                  })
                                  ?.map((row) => (
                                      <Row2
                                          key={row.projectionId}
                                          row={row}
                                          monthSingle={monthSingle}
                                      />
                                  ))
                            : rows
                                  ?.sort((first, second) => {
                                      return first.projectOwner >
                                          second.projectOwner
                                          ? 1
                                          : -1
                                  })
                                  ?.map((row) => (
                                      <Row2
                                          key={row.projectionId}
                                          row={row}
                                          monthSingle={monthSingle}
                                      />
                                  ))}
                    </TableBody>
                )}
            </Table>
        </div>
    )
}
