import {Error} from '../actions/types';
import { ErrorMessage } from '../Interfaces/ErrorMessageInterface';

const initialState: ErrorMessage = {
    errorMessage: '',
    errorMessageHeader: ''
};

export const errorReducer = (state = initialState, action: any): ErrorMessage => {
    if(action.type === Error.SET_ERROR_MESSAGE) {
        const {errorMessage} = action.data;
        return {
            ...state,
            errorMessage: errorMessage
        }
    } else {
        return state;
    }
};
