import React, { useState, useEffect, useRef } from 'react'
import { Col, Dropdown, DropdownButton, Form, NavDropdown, Overlay, OverlayTrigger, Pagination, Popover, Row } from 'react-bootstrap'
import { connect } from 'react-redux';
import { callWorkItemApi, setWorkItemList } from '../../../actions/WorkItemActions';
import SearchComponent from '../../../Components/Search/Search';
import { CustomMenu, CustomToggle } from '../../../Components/SearchSelect/SearchSelectDropDown';
import TaskList from '../../../Components/TaskList/TaskList';
import { AppConstant } from '../../../Constants';
import { WorkItem } from '../../../Interfaces/WorkItemInterface';
import './MyItems.css';
import DropdownMenu from 'react-overlays/DropdownMenu'
import { ButtonComponent } from '../../../Components/Button/Button';
import { CustomToggleFilter, CustomMenuFilter } from '../../../Components/WorkItemFilter/WorkItemFilter';
import { getAllUsers } from '../../../Services/ApplicationAndUserGroup/ApplicationAndUserService';
import { WorkItemStatusEnum } from '../../../Enum/WorkItemStatus.enum';
import { delteWatcherById, postWatchers, sendWorkItemForm } from '../../../Services/WorkItemAndTaskService/WorkItemList';
import { setUILoaderState } from '../../../actions/UIAction';
import moment from 'moment';


export function MyItems(props) {
    const ref = useRef(null);
    const [searchValue, setSearchValue] = useState('');
    const [tempObject, setTempObject] = useState({});
    const [filterValue, setfilterValue] = useState('Assigned To');
    const [ isDisabled, setIsDisabled ] = useState( false );
    const [isFilterTabOpen, setFilterTab] = useState<boolean>(false);
    const [userList, setuserList] = useState([]);
    const [taskList, setTaskList] = useState([]);
    const [show, setShowPopUp] = useState(false);
    const [showFirst, setShowFirst] = useState(false);
    const [workItemName, setWorkItemName] = useState('');
    const [activeRowTask, setActiveRowTask] = useState<boolean>(false);
    const [activeRowSubTask, setActiveRowSubTask] = useState<boolean>(false);
    const [parentWorkItemDetails, setParentWorkItemDetails] = useState<{
        id: number,
        guid: string | null, workItemTypeId: number
    }>({
        id: 0,
        guid: null,
        workItemTypeId: 3
    });
    const [filter, filterParams] = useState({
        userName: {
            name: 'Assigned To',
            id: 0,
            guid: ''
        },
        status: {
            name: 'Status',
            id: 0
        },
        updated: '',
        searchKeyWord: searchValue
    });
    const [initialFilter, setinitialFilter] = useState({
        userName: {
            name: 'Assigned To',
            id: 0,
            guid: ''
        },
        status: {
            name: 'Status',
            id: 0
        },
        updated: '',
        searchKeyWord: ''
    });
    const [workItem, setWorkItem] = useState<any>({
        name: workItemName,
        id: 0,
        guid: '',
        fileList: [],
        attachments: [],
        childWorkItems: [],
        isWatcherAssigned: false,
        assigneeName: 'Assigned To',
        assigneeId: 0,
        status: {
            id: 5,
            name: 'New'
        },
        project: {
            id: 0,
            name: 'Project'
        },
        priority: {
            id: 2,
            name: 'Normal'
        },
        visibility: {
            id: 2,
            name: 'Internal'
        },
        startDate: moment(new Date()).format("YYYY-MM-DD"),
        endDate: '',
        description: '',
        createdBy: localStorage.getItem('userId'),
        updatedBy: localStorage.getItem('userId')
    });
    let active = 2;
    let items: any = [];
    for (let number = 1; number <= 5; number++) {
        items.push(
            <Pagination.Item key={number} active={number === active}>
                {number}
            </Pagination.Item>,
        );
    }
    const paginationBasic = (
        <div>
            <Pagination>{items}</Pagination>
            <br />

            <Pagination size="lg">{items}</Pagination>
            <br />

            <Pagination size="sm">{items}</Pagination>
        </div>
    );


    const { WorkItem: { WorkItemList }, match, callWorkItemApi } = props;
    const [ workItems, setWorkItems ] = useState( WorkItemList );

    useEffect( () => {
        setWorkItems(WorkItemList);
        setTempObject({});
        setIsDisabled( false );
    }, [ WorkItemList.length ])

    const setFilterData = (type, value: any) => {
        if (type === 'userName') {
            const filterData = {
                ...filter,
                userName: {
                    name: value.firstName,
                    id: value.id,
                    guid: value.guid
                }
            }
            filterParams(filterData)
        } else if (type === 'status') {
            const filterData = {
                ...filter,
                status: {
                    name: value,
                    id: +WorkItemStatusEnum[value]
                }
            }
            filterParams(filterData)
        } else {
            const filterData = {
                ...filter,
                updated: value.target.value
            }
            filterParams(filterData)
        }
    }

    useEffect(() => {
        getAllUsers().then((response) => {
            if (response && response.data) {
                const { data } = response;
                const filteredUserList = data.filter(value => {
                    if (value.firstName != '') {
                        return value;
                    }
                })
                setuserList(filteredUserList);
            }
        });
    }, [])

    useEffect(() => {
        const filterData = {
            ...filter,
            searchKeyWord: searchValue
        }
        filterParams(filterData)
    }, [searchValue])

    const togglePopup = (flag) => {
        setShowPopUp(flag);
    }

    const getWorkItemList = (filterParams?: any) => {
        
        if (+match.params.tasklistId == 3) {
            callWorkItemApi(undefined, filterParams);
        } else if (+match.params.tasklistId === 1) {
            const userId = localStorage.getItem('userId');
            const userGuid = localStorage.getItem('guid');
            callWorkItemApi({ userId, userGuid, filterParams });
        } else if (+match.params.tasklistId === 2) {
            const paramsObject = Object.assign({}, {
                IsWatchedWorkItems: true,
                userId: localStorage.getItem('userId'),
                userGuid: localStorage.getItem('guid'),
                filterParams: filterParams
            });
            callWorkItemApi(paramsObject);
        }
    }

    useEffect(() => {
        if (document.getElementById(AppConstant.SEARCH)) {
            (document.getElementById(AppConstant.SEARCH) as HTMLInputElement).value = '';
        }
        filterParams(initialFilter);
        getWorkItemList();
    }, [props.match.params.tasklistId])

    const submitFilterData = () => {
        getWorkItemList(filter);
    }

    const submitFilterDataOnEnter = () => {
        submitFilterData()
    }

    const resetFormData = () => {
        getWorkItemList();
        togglePopup(false);
    }

    const checkNested = ( item, id ) => {
        return item.childWorkItems.findIndex( child => child.id === id );
    }

    const addQuickWorkItem = (event, showFirst = false, depth) => {
        event.preventDefault();
        setWorkItemName('');
        resetInput();
        setIsDisabled( showFirst );
        setShowFirst( showFirst );
        setTempObject( { depth, workItemName } );
        let formData = new FormData();
        formData.set("Description", workItem.description);
        formData.set("Name", workItemName);
        formData.set("StartDate", moment(workItem.startDate).format("MMM D YYYY"));
        formData.set("WorkItemStatusId", workItem.status.id);
        formData.set("WorkItemPriorityId", workItem.priority.id);
        formData.set("WorkItemVisibilityId", workItem.visibility.id);
        formData.set("UpdatedBy", workItem.updatedBy);
        formData.set("CreatedBy", workItem.createdBy);
        if (parentWorkItemDetails.id !== 0) {
            formData.set("ParentWorkItemId", `${parentWorkItemDetails.id}`)
            formData.set("ParentWorkItemGuid", `${parentWorkItemDetails.guid}`)
            formData.set("WorkItemTypeId", `${parentWorkItemDetails.workItemTypeId - 1}`);
        } else {
            formData.set("WorkItemTypeId", '3');
        }
        sendWorkItemForm(formData).then( async (response: any) => {            
            const updatedWorkItems = WorkItemList.map( item => {
                if( item.id === response.data.parentWorkItemId ) {
                    const body = { ...response.data };
                    body.updatedAt = body.updatedAt.split('T')[0];
                    body.depth = 1;
                    item.childWorkItems.push( body );
                    return { ...item, isOpen: true, showAddItemInput: true };
                } else if ( checkNested( item, response.data.parentWorkItemId ) > -1 ) {
                    const body = { ...response.data };
                    const nestedItem = checkNested( item, response.data.parentWorkItemId );
                    body.updatedAt = body.updatedAt.split('T')[0];
                    body.depth = 2;
                    item.childWorkItems[nestedItem].isOpen = true;
                    item.childWorkItems[nestedItem].showAddItemInput = true;
                    item.childWorkItems[nestedItem].childWorkItems.push( body );
                    return { ...item, isOpen: true, showAddItemInput: true };
                } else {
                    return item;
                }
            } );

            !showFirst && ( setTempObject({}) );
            
            getWorkItemList(filter);

            resetInput();
            
            setWorkItems(updatedWorkItems);
        });
    }

    const resetInput = () => {
        (document.getElementById('workitem-input') as HTMLInputElement).value = '';
            if ((document.getElementById('workitem-input-task') as HTMLInputElement) !== null)
                (document.getElementById('workitem-input-task') as HTMLInputElement).value = '';
            if ((document.getElementById('workitem-input-subtask') as HTMLInputElement) !== null)
                (document.getElementById('workitem-input-subtask') as HTMLInputElement).value = '';
    }

    const saveWorkItemParentIdGuId = (id: number, guid: string | null, workItemTypeId: number) => {
        setParentWorkItemDetails({
            id: id,
            guid: guid,
            workItemTypeId: workItemTypeId
        })
    }

    const deleteWatchItem = (watcherId: number, watcherGuid: string, watchers) => {
        const userId: any = localStorage.getItem('userId');
        const watcher = watchers.find(watcher => {
            return watcher.userId === +userId;
        })
        props.setUILoaderState(true);
        delteWatcherById(watcher.id, watcher.guid)
            .then(response => {
                getWorkItemList(filter);
            })
    }

    const addWorkItem = (workItemId: number, workItemUserId: number) => {
        const userId: any = localStorage.getItem('userId');
        const watcherList = [{
            workItemId: workItemId,
            userId: +userId
        }];
        postWatchers(watcherList).then((response: any) => {
            getWorkItemList(filter);
        })
    }

    const setWorkItemForInput = (item, items) => {
        makeShowInputFalse(items);
        if (item.workItemTypeId !== 1)
            item["showAddItemInput"] = !item["showAddItemInput"]
    }

    const makeShowInputFalse = (taskItem) => {
        taskItem.forEach(obj => {
            obj['showAddItemInput'] = false
            makeShowInputFalse(obj.childWorkItems)
        });
    }

    const dropDown = [`${filter.userName.name}`].map((data, index) => <Col key={index}
        className="mt-3"
        md={{ span: 12 }}>
        <Dropdown>
            <Dropdown.Toggle as={CustomToggle}
                value={filter.userName.name}
                id="dropdown-custom-components">
            </Dropdown.Toggle>
            <Dropdown.Menu as={CustomMenu}>
                {userList.map(({ id, firstName, guid }: any, index) => {
                    return <Dropdown.Item
                        key={index}
                        eventKey={`${index + 1}`}
                        onClick={() => setFilterData('userName', { firstName, id, guid })}
                    >
                        {firstName || null}
                    </Dropdown.Item>
                })}
            </Dropdown.Menu>
        </Dropdown>
    </Col>)
    const Assigned = [`${filter.status.name}`].map((data, index) => <Col key={index}
        className="mt-3"
        md={12}>
        <DropdownButton
            className="align-dropdown"
            key={"secondary"}
            id={`dropdown-variants-secondary`}
            variant={"secondary"}
            title={filter.status.name}
        >
            {[WorkItemStatusEnum[WorkItemStatusEnum.Assigned],
            WorkItemStatusEnum[WorkItemStatusEnum.Closed],
            WorkItemStatusEnum[WorkItemStatusEnum.Completed],
            WorkItemStatusEnum[WorkItemStatusEnum.InProgress],
            WorkItemStatusEnum[WorkItemStatusEnum.New]].map((data, index) => {
                return <Dropdown.Item
                    key={index}
                    eventKey={`${index + 1}`}
                    onClick={() => setFilterData('status', data)}
                >
                    {data}
                </Dropdown.Item>
            })}
        </DropdownButton>
    </Col>)
    return (
        <React.Fragment>
            <Row className="mt-2">
                <Col md={5}>
                    <SearchComponent
                        value={searchValue}
                        searchUserTable={setSearchValue}
                        style={{ 'float': 'inherit' }}
                        //   searchUserTable = {submitFilterDataOnEnter}
                        searchList={() => submitFilterData()}
                    />
                </Col>
                <Col md={{ span: 1, offset: 6 }}>
                    <OverlayTrigger
                        trigger="click"
                        key={'left'}
                        show={show}
                        rootClose
                        placement={'left'}
                        overlay={
                            <Popover id={`popover-positioned-filter-${'left'}`}>
                                <Popover.Content id="popover-body-filter">
                                    <div>
                                        {dropDown}
                                        {Assigned}
                                        <Col md={12}>
                                            <Form.Control
                                                value={filter.updated}
                                                onChange={(event) => setFilterData('updated', event)}
                                                type="date"
                                                className="project-data mt-3" />
                                        </Col>
                                        <Row className="justify-content-end mt-3 mb-3">
                                            <Col md={6} sm={6} className="text-center">
                                                <ButtonComponent
                                                    size='sm'
                                                    name="Reset"
                                                    onClick={() => { filterParams(initialFilter); resetFormData() }}
                                                    style={{ width: 70 }}
                                                />
                                            </Col>
                                            <Col md={6} sm={6} className="text-center">
                                                <ButtonComponent
                                                    size='sm'
                                                    disabled={JSON.stringify(initialFilter) === JSON.stringify(filter)}
                                                    name="Submit"
                                                    onClick={() => { submitFilterData(); togglePopup(false); }}
                                                    style={{ width: 70 }}
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                </Popover.Content>
                            </Popover>
                        }
                    >
                        <span onClick={() => { togglePopup(true) }} className="material-icons">
                            filter_alt
                  </span>
                    </OverlayTrigger>
                </Col>
            </Row>
            <Row className="mt-2">
                <TaskList
                    taskList={workItems.length === 0 ? WorkItemList : workItems }
                    setWorkItemList={props.setWorkItemList}
                    taskId={+match.params.tasklistId}
                    {...props}
                    showFirst={ showFirst }
                    filterParams={filterParams}
                    deleteWatchItem={deleteWatchItem}
                    addWorkItem={addWorkItem}
                    activeRowTask={activeRowTask}
                    setActiveRowTask={setActiveRowTask}
                    activeRowSubTask={activeRowSubTask}
                    setActiveRowSubTask={setActiveRowSubTask}
                    addQuickWorkItem={addQuickWorkItem}
                    setWorkItemName={setWorkItemName}
                    workItemName={workItemName}
                    setWorkItem={setWorkItemForInput}
                    tempObject={ tempObject }
                    saveWorkItemParentIdGuId={saveWorkItemParentIdGuId}
                />
            </Row>
            <Row className="mt-2">
                <div className="add-workItem-button">
                    {/* <span className="material-icons">
                    add
                </span> */}
                    <Form className='sub-form' onSubmit={(event) => addQuickWorkItem(event, true, 0)}>
                        <div className='input-holder'>
                            <input id="workitem-input"
                                disabled={ isDisabled }
                                onChange={(event) => setWorkItemName(event.target.value)}
                                placeholder="Add Work Item" />
                            <button type="submit" className="span-item">
                                <i className="material-icons icon-style"> arrow_right
                          </i>
                            </button>
                        </div>
                    </Form>
                </div>
            </Row>
        </React.Fragment>
    )
}

const mapStateToProps = (workItem: WorkItem) => {
    return workItem;
};

const mapDispatchToProps = (dispatch) => ({
    callWorkItemApi: (params?: any, filterParams?: any) => dispatch(callWorkItemApi(params, filterParams)),
    setWorkItemList: (workItem: WorkItem[]) => dispatch(setWorkItemList(workItem)),
    setUILoaderState: (isLoading: boolean) => dispatch(setUILoaderState(isLoading))
});

export default connect(mapStateToProps, mapDispatchToProps)(MyItems);
