import {TemplateList} from '../actions/types';
import { Template, TemplateReducerModel } from '../Interfaces/TemplateInterface';
import { WorkItemReducer } from '../Interfaces/WorkItemInterface';

const initialState: TemplateReducerModel = {
    TemplateList: [],
    pagination: {
        currentPage: 1, 
        hasNext: false, 
        hasPrevious: false, 
        pageSize: 10, 
        totalCount: 0, 
        totalPages: 1
    }
};

export const TemplateReducer = (state = initialState, action: any): TemplateReducerModel => {
    if(action.type === TemplateList.SET_TEMPLATE) {
        const {Template} = action.data;
        return {
            ...state,
            TemplateList: [...Template]
        }
    } else if(action.type === TemplateList.TEMPLATE_PAGINATION) {
        return {
            ...state,
            pagination: {...action.data}
        }
    } else {
        return state;
    }
};
