import React, { Component, useState } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import MyStatefulEditor from '../Text-Editor/TextEditor'
import './Comments.css';
import noImg from '../../Assets/Images/no-img.png';
import moment from 'moment';

export function Comments(props) {
    const userId = localStorage.getItem('userId');
    const {commentList} = props;
        return( <React.Fragment>
                <div className = "mt-3 set-fontSize set-padding">
                <Row className = "chat-height">
                    {
                        commentList.map((comment, index) => {
                            return ( <Col md = {8}
                                key = {index}
                                className = {`${comment.userId == userId ? 'my-comments'
                                : null   
                                }`}>
                                <Card className = "mt-2 ml-1">
                                    <Card.Body className = "comment-color">
                                        <div>
                                            <img id = "avatar-comments" src = {noImg}></img>
                                            <span className = "ml-1">
                                                <strong>{comment.userName}</strong></span>
                                            <span className = "set-date">
                                            <i>
                                            {moment(comment.createdAt).format("MM/DD/YYYY, h:mm:ss a")}
                                                {/* 09/09/2020 10:30pm */}
                                            </i>
                                            </span>
                                        </div>
                                        <Card.Text className = "mt-2">
                                           {comment.description}
                                        </Card.Text>
                                        {
                                            comment.userId == userId ? <Card.Text className = "text-right">
                                            <small className="text-muted">
                                            <span className="material-icons" onClick = {() => {
                                                props.deleteComment(props.curRef, comment.id, comment.guid)
                                            }}>
                                                delete
                                            </span>
                                        </small>
                                        </Card.Text>
                                            :
                                            null
                                        }
                                    </Card.Body>
                                </Card>
                            </Col>
                            )
                        })
                    }
                </Row>
                </div>
                <div className = "mt-3 pr-3 pl-3">
                <MyStatefulEditor
                    onChange = {props.setCommentDescription}
                    curRef = {props.curRef}
                    value = {props.value}
                />
                </div>
                </React.Fragment>
        )
}

export default Comments
