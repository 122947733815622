import React, {Component} from 'react';
import RichTextEditor from 'react-rte';
 
export default class MyStatefulEditor extends Component<any, any> {
 
  state = {
    value: RichTextEditor.createEmptyValue(),
    disabled: false
  }
 
  onChange = (value) => {
    // this.setState({value});
    if (this.props.onChange) {
      this.props.onChange(
        this.props.curRef,
        value.toString('html'),
        value
      );
    }
  };
 
  render () {
    return (
      <RichTextEditor
        value={this.props.value}
        disabled={this.props.disabled}
        onChange={this.onChange}
      />
    );
  }
}