import React, { Component, useEffect } from 'react'
import styles from './Root.module.css'

const Root = () => {
    return (
        <div className={styles.rootContainer}>
            <iframe
                style={{
                    width: '100%',
                    height: 'calc(100vh - 150px)',
                }}
                src="https://www.optimusinfo.com/blog"
            ></iframe>
        </div>
    )
}

export default Root
