import { MsalAuthProvider, LoginType } from "react-aad-msal";

import {AuthConfifModel} from '../../Models/AuthenticationModels/AuthConfig.model';
import {AuthenticationParameter} from '../../Models/AuthenticationModels/Parameter.model';

import {AuthenticationConstants} from '../Constants';

const {CLIENT_ID, TENENT_ID, REDIRECT_URI, 
  AUTHORITY, CACHE_LOCATION, SCOPES} = AuthenticationConstants;

// Msal Configurations
const config: AuthConfifModel = {
    auth: {
      authority: AUTHORITY + TENENT_ID,
      clientId: CLIENT_ID,
      redirectUri: REDIRECT_URI
    },
    cache: {
      cacheLocation: CACHE_LOCATION,
      storeAuthStateInCookie: true
    }
};

// Authentication Parameters
const authenticationParameters: AuthenticationParameter = {
    scopes: SCOPES
};

// Options
const options = {
    loginType: LoginType.Redirect,
    tokenRefreshUri: window.location.origin + '/auth.html'
};

export const authProvider = new MsalAuthProvider(config, authenticationParameters, options);
