export const UIActionConstants = {
    SET_ACTIVE_ROUTE_ID :'SET_ACTIVE_ROUTE_ID',
    HEADER_HEADING: '',
    MODAL_STATE: 'MODAL_STATE',
    MODAL_FUNCTION: 'MODAL_FUNCTION',
    ASYNC_MODAL_FUNCTION: 'ASYNC_MODAL_FUNCTION',
    IS_LOADING: 'IS_LOADING',
    API_RESPONSE: 'API_RESPONSE'
}

// export const AuthActionConstants = {
//     SET_ACTIVE_ROUTE_ID :'SET_ACTIVE_ROUTE_ID',
//     CLOSE_ACTIVE_ROUTE_ID: 'CLOSE_ACTIVE_ROUTE_ID'
// }