import React, { Component } from 'react'
import SupportTicket from '../../Components/SupportTicket/SupportTicket'
import { Tabs, Tab, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { ButtonName, Routes } from '../../Constants'
import ButtonComponent from 'Components/Button/Button'

class SupportTickets extends Component {
    AssigneeGuidValue: any
    SupportTicketDepartmentMemberGuidValue: any
    UserGuidValue: any
    currentTab: any
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        console.log(localStorage.getItem('supportGroupMemberGuid'))
    }

    render() {
        return (
            <React.Fragment>
                <Link
                    to={Routes.CREATE_SUPPORT_TICKET}
                    style={{ float: 'right' }}
                >
                    <ButtonComponent
                        style={{ fontSize: '14px' }}
                        name={ButtonName.CREATE_SUPPORT_TICKET}
                        onClick={() => console.log('clicked')}
                    />
                </Link>
                <Tabs
                    defaultActiveKey="listOfTickets"
                    id="uncontrolled-tab-example"
                >
                    <Tab
                        eventKey="listOfTickets"
                        title="My Tickets"
                        statusSet={new Set([])}
                    >
                        <div id="firstTab">
                            <SupportTicket
                                UserGuid={localStorage.getItem('guid')}
                                currentTab={1}
                            />
                        </div>
                    </Tab>
                    {localStorage.getItem('supportGroupMemberGuid') ==
                    '' ? null : (
                        <Tab
                            eventKey="assignedTickets"
                            title="Assigned Tickets"
                        >
                            <div id="secondTab">
                                <SupportTicket
                                    AssigneeGuid={localStorage.getItem(
                                        'supportGroupMemberGuid'
                                    )}
                                    assignee
                                    currentTab={2}
                                />
                            </div>
                        </Tab>
                    )}
                    {localStorage.getItem('supportGroupDepartmentId') ==
                    '' ? null : (
                        <Tab eventKey="department" title="Department">
                            <div id="thirdtab">
                                <SupportTicket
                                    SupportTicketDepartmentMemberGuid={localStorage.getItem(
                                        'supportGroupMemberGuid'
                                    )}
                                    department
                                    currentTab={3}
                                />
                            </div>
                        </Tab>
                    )}
                </Tabs>
            </React.Fragment>
        )
    }
}

export default SupportTickets
