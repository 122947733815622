import * as React from 'react'
import Box from '@material-ui/core/Box'
import Collapse from '@material-ui/core/Collapse'
import Typography from '@material-ui/core/Typography'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import {
    Col,
    Dropdown,
    DropdownButton,
    Nav,
    OverlayTrigger,
    Popover,
    Row,
} from 'react-bootstrap'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'
import { useEffect, useState } from 'react'
import { getProjectsFromKickOffBilling } from 'Services/ResourceService/ResourceService'
import AddDeleteTableRows from './AddDeleteTableRows'
import {
    getAllocationResourceDetails,
    getAllocationResources,
} from 'Services/AllocationService/AllocationService'
import { TableContainer } from '@material-ui/core'

interface State {
    isLocked?: boolean
    projectionDropDownItems?: any
}
function Row2(props: { row; month: any; year: any }) {
    const { month, year } = props
    const { row } = props
    const [open, setOpen] = useState(false)
    const [resourceLoader, setResourceLoader] = useState(true)
    const [projectLoader, setprojectLoader] = useState(true)
    const [resourceData, setResourceData]: any = useState()
    const [projectResources, setProjectResources]: any = useState()
    const [projectId, setProjectId]: any = useState()
    useEffect(() => {
        setOpen(false)
        setResourceLoader(true)
        setprojectLoader(true)
        setResourceData()
        setProjectResources()
    }, [month, year])

    const getProjectResources = (projectId: any) => {
        getAllocationResourceDetails(month, year, projectId)
            .then((response: any) => {
                if (response.data) {
                    setResourceData(response.data)
                    setResourceLoader(false)
                }
            })
            .catch((error) => console.log(error, ' error'))
        getAllocationResources(month, year)
            .then((response: any) => {
                if (response.data) {
                    setProjectResources(
                        response.data.sort((a, b) => a.localeCompare(b))
                    )
                    setprojectLoader(false)
                }
            })
            .catch((error) => console.log(' Error'))
        setOpen(!open)
    }
    return (
        <React.Fragment>
            <TableRow>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => getProjectResources(row.id)}
                    >
                        {open ? (
                            <KeyboardArrowUpIcon />
                        ) : (
                            <KeyboardArrowDownIcon />
                        )}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.name}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={6}
                >
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography
                                variant="h6"
                                gutterBottom
                                component="div"
                            ></Typography>
                            <Table
                                size="small"
                                aria-label="purchases"
                                className="allocation-table"
                            >
                                {resourceLoader || projectLoader ? (
                                    <span className="loader-list">
                                        loading..
                                    </span>
                                ) : (
                                    <AddDeleteTableRows
                                        resourceData={resourceData}
                                        projectResources={projectResources}
                                        month={month}
                                        year={year}
                                        projectId={row.id}
                                    />
                                )}
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    )
}

export default function AllocationProjects(state: State) {
    state = {
        isLocked: false,
        projectionDropDownItems: {
            projectId: {
                id: 0,
                name: 'Project',
            },
            projectOwnerId: {
                id: 0,
                name: 'PO',
            },
            year: {
                id: 0,
                name: 'Year',
            },
            month: {
                id: 0,
                name: 'Month',
            },
        },
    }
    const [monthSingle, setMonthSingle]: any = useState({
        firstMonth: 'Month 1',
        secondmonth: 'Month 2',
    })
    const [startDate, setStartDate] = useState(new Date())
    const [sortStatus, setSortstatus]: any = useState(true)
    const [projectionState, setProjectionState] = useState(state)
    const [years, setYears]: any = useState([])
    const [rows, setRows]: any = useState([])
    const [uiLoader, setUiLoader] = useState(true)
    const [showMsg, setShowMsg] = useState(true)
    const [message, setMessage] = useState('')
    const [loadMessage, setLoadMessage] = useState('')
    const roleName = localStorage.getItem('adminRole')
    let monthPairs = [
        { id: '1', name: 'Jan - Feb', firstMonth: 'Jan', secondmonth: 'Feb' },
        { id: '2', name: 'Feb - Mar', firstMonth: 'Feb', secondmonth: 'Mar' },
        { id: '3', name: 'Mar - Apr', firstMonth: 'Mar', secondmonth: 'Apr' },
        { id: '4', name: 'Apr - May', firstMonth: 'Apr', secondmonth: 'May' },
        { id: '5', name: 'May - Jun', firstMonth: 'May', secondmonth: 'Jun' },
        { id: '6', name: 'Jun - Jul', firstMonth: 'Jun', secondmonth: 'Jul' },
        { id: '7', name: 'Jul - Aug', firstMonth: 'Jul', secondmonth: 'Aug' },
        { id: '8', name: 'Aug - Sep', firstMonth: 'Aug', secondmonth: 'Sep' },
        { id: '9', name: 'Sep - Oct', firstMonth: 'Sep', secondmonth: 'Oct' },
        { id: '10', name: 'Oct - Nov', firstMonth: 'Oct', secondmonth: 'Nov' },
        { id: '11', name: 'Nov - Dec', firstMonth: 'Nov', secondmonth: 'Dec' },
        { id: '12', name: 'Dec - Jan', firstMonth: 'Dec', secondmonth: 'Jan' },
    ]

    useEffect(() => {
        let options: any = []
        let prevYear = 2000
        for (let i = 0; i <= 40; i++) {
            options.push((prevYear = prevYear + 1))
        }
        setYears([...years, ...options])
        let currentMonth = new Date().getMonth() + 1
        let monthResult = monthPairs.find((months) => {
            return months.id == currentMonth + ''
        })

        const dropDownValue = {
            ...projectionState,
            projectionDropDownItems: {
                ...projectionState.projectionDropDownItems,
                month: {
                    id: monthResult?.id,
                    name: monthResult?.name,
                },
                year: {
                    id: new Date().getFullYear(),
                    name: new Date().getFullYear(),
                },
            },
        }
        setProjectionState(dropDownValue)
        setMonthSingle({
            ...monthSingle,
            firstMonth: monthResult?.firstMonth,
            secondmonth: monthResult?.secondmonth,
        })
    }, [])
    useEffect(() => {
        setLoadMessage('Loading...')
        try {
            getProjectsFromKickOffBilling()
                .then((response: any) => {
                    if (response.data) {
                        setRows(response.data)
                        setUiLoader(false)
                    } else {
                        setUiLoader(true)
                        setLoadMessage('No Data Found')
                    }
                })
                .catch((error) => console.log('Errorrr'))
        } catch (error) {}
    }, [
        projectionState.projectionDropDownItems.year.id,
        projectionState.projectionDropDownItems.month.id,
    ])
    const setDropDownValue = (type: string, value: any) => {
        if (type === 'projectId') {
            const dropDownValue = {
                ...projectionState,
                projectionDropDownItems: {
                    ...projectionState.projectionDropDownItems,
                    projectId: {
                        id: value.id,
                        name: value.name,
                    },
                },
            }
            setProjectionState(dropDownValue)
        } else if (type === 'year') {
            const dropDownValue = {
                ...projectionState,
                projectionDropDownItems: {
                    ...projectionState.projectionDropDownItems,
                    year: {
                        id: value,
                        name: value,
                    },
                },
            }
            setProjectionState(dropDownValue)
        } else if (type === 'month') {
            const dropDownValue = {
                ...projectionState,
                projectionDropDownItems: {
                    ...projectionState.projectionDropDownItems,
                    month: {
                        id: value.id,
                        name: value.name,
                    },
                },
            }
            setProjectionState(dropDownValue)
            setMonthSingle({
                ...monthSingle,
                firstMonth: value.firstMonth,
                secondmonth: value.secondmonth,
            })
        }
    }

    return roleName != 'Admin' ? (
        <div className="loader-list">Loading....</div>
    ) : (
        <React.Fragment>
            <TableContainer style={{ maxHeight: '33rem' }}>
                <Table stickyHeader>
                    <div>
                        <div className="right-holder">
                            <Nav.Item id="button-nav-project">
                                <Row>
                                    <Col>
                                        {
                                            <DropdownButton
                                                className={`align-dropdown 
                                   assign-title-center dropdown-font`}
                                                key={'secondary'}
                                                id={`dropdown-variants-secondary`}
                                                variant={'secondary'}
                                                title={
                                                    projectionState
                                                        .projectionDropDownItems
                                                        .month.name
                                                }
                                            >
                                                {monthPairs.map(
                                                    (data: any, index) => {
                                                        return (
                                                            <Dropdown.Item
                                                                key={index}
                                                                eventKey={`${
                                                                    index + 1
                                                                }`}
                                                                onClick={() =>
                                                                    setDropDownValue(
                                                                        'month',
                                                                        data
                                                                    )
                                                                }
                                                            >
                                                                {data.name}
                                                            </Dropdown.Item>
                                                        )
                                                    }
                                                )}
                                            </DropdownButton>
                                        }
                                    </Col>
                                    <Col style={{ marginRight: '30px' }}>
                                        <DropdownButton
                                            className={`align-dropdown 
                                        assign-title-center dropdown-font`}
                                            key={'secondary'}
                                            id={`dropdown-variants-secondary`}
                                            variant={'secondary'}
                                            title={
                                                projectionState
                                                    .projectionDropDownItems
                                                    .year.name
                                            }
                                        >
                                            {years.map((data, index) => {
                                                return (
                                                    <Dropdown.Item
                                                        key={index}
                                                        eventKey={`${
                                                            index + 1
                                                        }`}
                                                        onClick={() =>
                                                            setDropDownValue(
                                                                'year',
                                                                data
                                                            )
                                                        }
                                                    >
                                                        {data}
                                                    </Dropdown.Item>
                                                )
                                            })}
                                        </DropdownButton>
                                    </Col>
                                </Row>
                            </Nav.Item>
                        </div>
                        {showMsg && (
                            <span style={{ marginLeft: '30px' }}>
                                {' '}
                                {message}
                            </span>
                        )}
                        <Table aria-label="collapsible table">
                            {uiLoader ? (
                                <span className="loader-list">
                                    {loadMessage}
                                </span>
                            ) : (
                                <TableBody>
                                    {rows
                                        .sort((first, second) => {
                                            return first.name > second.name
                                                ? 1
                                                : -1
                                        })
                                        .map((row) => (
                                            <Row2
                                                key={row.id}
                                                row={row}
                                                month={
                                                    projectionState
                                                        .projectionDropDownItems
                                                        .month.id
                                                }
                                                year={
                                                    projectionState
                                                        .projectionDropDownItems
                                                        .year.id
                                                }
                                            />
                                        ))}
                                </TableBody>
                            )}
                        </Table>
                    </div>
                </Table>
            </TableContainer>
        </React.Fragment>
    )
}
