import React, { useState, useEffect } from 'react'
import { Form, OverlayTrigger, Popover, Row, Table } from 'react-bootstrap';

function ChildWorkItemList(props) {
  const [renderList, setRenderList] = useState([]);

  function renderSubMenu(parent, children) {
    if (children && children.length > 0 && parent.showAddItemInput) {
      return (
        <React.Fragment>
          {renderMenuItems(children, parent)}
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        {parent.showAddItemInput ? <Row className="mt-2 mb-2" style={{
          'paddingLeft': '5%'
        }}>
          <div className="add-workItem-button">
            <Form onSubmit={(event) => props.addChildAndSubTask(event, props.thisRef)}>
              <div className='input-holder'>
                <input id="workitem-input"
                  onChange={(event) => props.setWorkItemName(event.target.value, props.thisRef)}
                  onClick={() => props.saveWorkItemParentIdGuId(
                    parent.id, parent.guid, parent.workItemTypeId, props.thisRef
                  )}
                  placeholder="Add Sub Task"
                />
                <button type="submit" className="span-item">
                  <i className="material-icons icon-style"> arrow_right </i>
                </button>
              </div>
            </Form>
          </div>
        </Row> : null}
      </React.Fragment>
    );
  }

  const renderItems = (item, index, parent?: any, items?: any) => <React.Fragment key={index}>
    <tr className={`${item.showAddItemInput ? `table-row ${item && item.showAddItemInput}` : null}`}>
      <td onClick={() => props.setWorkItemForInput(item, items, props.thisRef)} id="onHover-row" style={{ 'paddingLeft': `${item.depth * 3}%` }}>
        <div className="first-column">
          {
            item && item.showAddItemInput
            ? 
            <span className="material-icons">
              keyboard_arrow_up
            </span>
            :
            <span className="material-icons">
                keyboard_arrow_down
            </span>
          }
          <span>
            {item.name || '-'}
          </span>
        </div>
      </td>
      <td className="text-right center">
        {item.assigneeName || '-'}
      </td>
      <td className='center'>
        {props.readOnly && <OverlayTrigger
          trigger="click"
          key={'right'}
          rootClose
          placement={'right'}
          overlay={
            <Popover id={`popover-positioned-${'right'}`}>
              <Popover.Content>
                <ul id="popover-list">
                  <li onClick={(event: any) => {
                      event.target.style.pointerEvents = 'none';
                      event.target.style.opacity = 0.6;
                      props.deleteChildWorkItem(props.thisRef, item.id, item.guid);
                    }}>Delete</li>
                </ul>
              </Popover.Content>
            </Popover>
          }
        >
          <span className="material-icons">
            more_horiz
          </span>
        </OverlayTrigger>}
      </td>
    </tr>
    {renderSubMenu(item, item.childWorkItems)}
  </React.Fragment>

  function renderMenuItems(items, parent?: any) {
    const itemsToRender = items.map((item, index) => {
      return renderItems(item, index, parent, items)
    })
    return <React.Fragment>
      {itemsToRender}
      {parent && parent.showAddItemInput ? <Row className="mt-2 mb-2" style={{
        'paddingLeft': '5%'
      }}>
        <div className="add-workItem-button" onClick={() => {
          props.saveWorkItemParentIdGuId(parent.id, parent.guid, parent.workItemTypeId, props.thisRef)
        }}>
          <Form onSubmit={(event) => props.addChildAndSubTask(event, props.thisRef)}>
            <div className='input-holder'>
              <input id="workitem-input"
                onChange={(event) => props.setWorkItemName(event.target.value, props.thisRef)}
                placeholder="Add Sub Task" />
              <button type="submit" className="span-item">
                <i className="material-icons icon-style"> arrow_right </i>
              </button>
            </div>
          </Form>
        </div>
      </Row> : null}
    </React.Fragment>
  }
  return (
    <Table responsive hover size="sm">
      <tbody id="tbody">
        {
          renderMenuItems(props.list)
        }
      </tbody>
    </Table>
  )
}

export default ChildWorkItemList;
