import ButtonComponent from 'Components/Button/Button'
import { AppConstant, ButtonName, Routes } from 'Constants'
import React, { useEffect, useState } from 'react'
import { Col, Nav, OverlayTrigger, Popover, Row, Table } from 'react-bootstrap'
import { getUsersList } from 'Services/ResourceService/ResourceService'
import { useHistory } from 'react-router-dom'
import SearchComponent from 'Components/Search/Search'

function ResourceList() {
    const [userList, setUserList]: any = useState([])
    const [searchTerm, setSearchTerm] = useState('')
    const [filteredResults, setFilteredResults] = useState([])
    const [uiLoader,setUiLoader]=useState(true);
    const history = useHistory()
   
    const roleName = localStorage.getItem('adminRole');
    
    
    useEffect(() => {
        getUsersList().then((response: any) => {
           
            setUserList(response.data)
            setUiLoader(false)
        })
        
    }, [])

    const editItem = (userPrincipalName, userId) => {
        history.push({
            pathname: Routes.RESOURCE_MANAGER_EDIT,
            state: { userPrincipalName: userPrincipalName, userId: userId },
        })
    }
    const renderResources = (userData) => {
        
        
        return (
           
            <React.Fragment>
                <tr>
                    <td>
                        <span>{userData.displayName || '-'}</span>

                        {/* <td>
          <span>{item.id === 0 ? '-' : item.state || '-'}</span>
        </td> */}
                    </td>
                    <td>
                        <span>{userData.userPrincipalName || '-'}</span>
                    </td>

                    <td>
                        {
                            <span
                                onClick={() => {
                                    editItem(
                                        userData.userPrincipalName,
                                        userData.id
                                    )
                                }}
                                className="material-icons"
                            >
                                edit
                            </span>
                        }
                    </td>
                </tr>
            </React.Fragment>)
        
    }

    const searchData = (value) => {
      setSearchTerm(value)
      if (searchTerm !== '') {
          const filteredData = userList.filter((item) => {
              return Object.values(item).join('').toLowerCase().includes(searchTerm.toLowerCase())
          })
          setFilteredResults(filteredData)
      }
      else {
          setFilteredResults(userList)
      }
  }
    return (
        uiLoader || roleName!="Admin"?(<div className="loader-list">Loading....</div>):(
        <div>
            <React.Fragment>
            <div className="right-holder">
                        
                            <input
                            className="search-bar"
                                type="text"
                                placeholder="Search.."
              
                                name="search"
                                id="search"
                                onChange={(e) => searchData(e.target.value)}
                            />
                        
                  
               
                    <Nav.Item id="button-nav">
                        {
                            <ButtonComponent
                                style={{ fontSize: '14px' }}
                                name={'Add New'}
                                onClick={() =>
                                    history.push(
                                        Routes.RESOURCE_MANAGER_TOOLS_NEW
                                    )
                                }
                            />
                        }
                    </Nav.Item>
                </div>
                <div className="table-user">
                    <Table responsive hover size="sm">
                        <thead>
                            <tr id="tableHeading">
                                <th>Name</th>
                                <th>Email</th>

                                <th></th>
                            </tr>
                        </thead>
                        <tbody id="tbody">
                          {
                            searchTerm.length > 1 ? (
                              filteredResults.map((userData, index) => {
                                return renderResources(userData)
                              })
                          ):(
                            userList.sort((first, second) => {
                                return first.displayName >
                                    second.displayName
                                    ? 1
                                    : -1
                            }).map((userData, index) => {
                              return renderResources(userData)
                          })
                          )
                          }

                          
                            {/* {userList.map((userData, index) => {
                                return renderResources(userData)
                            })} */}
                        </tbody>
                    </Table>
                </div>
            </React.Fragment>
        </div>)
    )
}

export default ResourceList
