import React, { Component } from 'react'

export class Report extends Component {
    render() {
        return (
            <div style = {{textAlign: 'center'}}>
                <h1>Coming Soon</h1>
            </div>
        )
    }
}

export default Report
