import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import './SideNav.css'

import NavContent from './NavContent'
import PopUpController from '../PopOver/PopUpControl'
import PopUpSelect from '../PopOver/PopUpSelect'
import PopUp from '../PopOver/PopUp'

import { NavigationConst } from './NavigationData'

import { UIState } from '../../Interfaces/UIStateInterface'

import { setActiveRoute, setHeaderHeading } from '../../actions/UIAction'
import { useHistory } from 'react-router-dom'
import { AppConstant, Routes } from '../../Constants'

interface Props {
    setActiveRoute: (routeId: number) => void
    UIState: UIState
    setHeaderHeading: (name: string) => void
    children: React.ReactNode[]
    applyClass?: string
}

const SideNav = (props: Props) => {
    useEffect(() => {
        let id = 1,
            name = 'Home'
        const pathname = window.location.pathname

        if (pathname === '/') {
            id = 1
            name = 'Home'
        } else if (pathname === '/dashboard') {
            id = 2
            name = 'Applications'
        } else if (pathname === '/resourceManagerTools') {
            id = 7
            name = 'Manager Tools'
        } else if (pathname.indexOf('/policies') > -1) {
            id = 4
            name = 'Policies'
        } else if (pathname.includes('/supportTicket')) {
            id = 5
            name = 'Support Ticket'
        } else if (pathname.includes('/createSupportTicket')) {
            id = 5
            name = 'Support Ticket'
        } else if (pathname.includes('/editSupportTicket')) {
            id = 5
            name = 'Support Ticket'
        } else if (pathname.includes('/projections')) {
            id = 6
            name = 'Projection'
        } else if (pathname.includes('/resourceManagerToolsNew')) {
            id = 7
            name = 'Manager Tools'
        } else if (pathname.includes('/resourceManagerTools')) {
            id = 7
            name = 'Manager Tools'
        } else if (pathname.includes('/resourceManagerEdit')) {
            id = 7
            name = 'Manager Tools'
        } else if (pathname.includes('/allocationContainer')) {
            id = 8
            name = 'Allocations'
        } else if (pathname.includes('/reportsContainer')) {
            id = 9
            name = 'Reports'
        } else {
            id = 3
            name = 'Work Items'
        }

        setActiveRoute(id)
        setActiveMenu(id)
        setHeaderHeading(name)
    }, [])

    const history = useHistory()
    let { applyClass, setActiveRoute, setHeaderHeading } = props
    applyClass = 'side-nav-container'
    const [activeMenu, setActiveMenu] = useState(0)
    const roleName = localStorage.getItem('adminRole')
    const clearActiveState = () => {
        // 5 = no of side nav - 1
        for (
            let noOfSideNav = 1;
            noOfSideNav < NavigationConst.length;
            noOfSideNav++
        ) {
            setActiveMenu(0)
        }
    }
    //set active route on side nav click
    const clickSideNav = (elementId, name) => {
        clearActiveState()
        setActiveRoute(elementId)
        setHeaderHeading(name)
        setActiveMenu(elementId)
        if (elementId === 1) {
            history.push(Routes.HOME)
        } else if (elementId === 4) {
            history.push(Routes.POLICIES)
        } else if (elementId === 6) {
            history.push(Routes.PROJECTION_CONTAINER)
        } else if (elementId === 7) {
            history.push(Routes.RESOURCE_MANAGER_TOOLS)
        } else if (elementId === 8) {
            history.push(Routes.ALLOCATION_CONTAINER)
        } else if (elementId === 9) {
            history.push(Routes.REPORTS_CONTAINER)
        } else if (elementId === 5) {
            history.push(Routes.SUPPORT_TICKET)
        } else {
            elementId === 2
                ? history.push(Routes.DASHBOARD)
                : history.push(`${Routes.TASK_LIST}/1`)
        }
    }
    const {
        UIState: { activeRouteId },
    } = props

    return (
        <div className={applyClass}>
            {roleName != 'Admin'
                ? NavigationConst.filter(
                      (item) => item.link != '/resourceManagerTools'
                  ).map((navConst, index) => {
                      const { imageUrl, name } = navConst
                      return (
                          <PopUpController
                              detect="click"
                              offsetX="center"
                              isOpen={false}
                              key={index}
                              offsetY={-111}
                          >
                              <PopUpSelect>
                                  <div>
                                      <NavContent
                                          imageUrl={imageUrl}
                                          name={name}
                                          clickSideNav={clickSideNav}
                                          activeMenu={activeRouteId}
                                          id={index + 1}
                                      ></NavContent>
                                  </div>
                              </PopUpSelect>
                              <PopUp />
                          </PopUpController>
                      )
                  })
                : NavigationConst.map((navConst, index) => {
                      const { imageUrl, name } = navConst
                      return (
                          <PopUpController
                              detect="click"
                              offsetX="center"
                              isOpen={false}
                              key={index}
                              offsetY={-111}
                          >
                              <PopUpSelect>
                                  <div>
                                      <NavContent
                                          imageUrl={imageUrl}
                                          name={name}
                                          clickSideNav={clickSideNav}
                                          activeMenu={activeRouteId}
                                          id={index + 1}
                                      ></NavContent>
                                  </div>
                              </PopUpSelect>
                              <PopUp />
                          </PopUpController>
                      )
                  })}
        </div>
    )
}

const mapStateToProps = (UIState) => {
    return UIState
}

const mapDispatchToProps = (dispatch) => ({
    setActiveRoute: (routeId: number) => dispatch(setActiveRoute(routeId)),
    setHeaderHeading: (name: string) => dispatch(setHeaderHeading(name)),
})

export default connect<Props>(
    mapStateToProps,
    mapDispatchToProps
)(SideNav as any)
