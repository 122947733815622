import React, {useState} from 'react';
import { Row, Col, Toast } from 'react-bootstrap';

function ToastMessage(props) {
    const [show, setShow] = useState(true);
  
    return (
      <Row>
        <Col xs={6}>
          <Toast 
            style={{
                position: 'absolute',
                top: '-30px',
                right: '-105%',
                zIndex: 99,
                marginTop: '2%',
            }}
          onClose={() => props.setShow(!show)}
          show={props.show} 
          delay={3000} 
          autohide
          >
            <Toast.Header>
              <img
                src="holder.js/20x20?text=%20"
                className="rounded mr-2"
                alt=""
              />
              <strong className="mr-auto">Success</strong>
              {/* <small>11 mins ago</small> */}
            </Toast.Header>
          <Toast.Body>Record updated successfully</Toast.Body>
          </Toast>
        </Col>
      </Row>
    );
}
export default ToastMessage;
  