import axios from '../Interceptor';
import { API_URL } from '../Constants';
import {AxiosError} from 'axios';

const makeFilterParameters = (filterParams) => {
    const param: any = {};
    if(filterParams.updated !== '') {
        param.UpdatedAt = filterParams.updated;
    } if(filterParams.userName.id !== 0) {
        param.userId = filterParams.userName.id;
    } if(filterParams.userName.guid !== "") {
        param.userGuid = filterParams.userName.guid;
    } if(filterParams.status.id !== 0) {
        param.workItemStatusId = filterParams.status.id;
    } if(filterParams.searchKeyWord !== "") {
        param.searchKeyWord = filterParams.searchKeyWord;
    }
    return param;
}

const getWorkItemList = async(params?: any, filterParams?: any) => {
    try {
        let baseUrl: string;
        let response: any;
        let param: any;
        if(filterParams !== undefined) {
            param = makeFilterParameters(filterParams);
        }
        if(params === undefined) {
            baseUrl = `${API_URL}/api/work-items`
            response = await axios.get(baseUrl, {params: param});
        } else {
            const {userId, userGuid, IsWatchedWorkItems, filterParams: parameter} = params;
            if(parameter === undefined) {
                param = null;
            } else {
                param = makeFilterParameters(parameter);
            }
            baseUrl = IsWatchedWorkItems ? `${API_URL}/api/work-items`:`${API_URL}/api/work-items`;
            if(IsWatchedWorkItems) {
                response = await axios.get(baseUrl, { 
                    params: { IsWatchedWorkItems: true,
                              userId: userId,
                              userGuid: userGuid,
                              ...param
                            }});
            } else {
                const userId: any = localStorage.getItem('userId'); 
                const userGuId: string = localStorage.getItem('guid') as string;
                response = await axios.get(baseUrl, {params: {
                    ...param,
                    userId: +userId,
                    userGuid: userGuId,
                    IsWatchedWorkItems: false
                }});
            }
        }
        const data = response.data.data;
        if (response.status >= 400) {
            console.log(data.errors);
        }
        return data;
    } catch(e) {
        console.log(e);
    }
};

const deleteWorkItemService = async(id: number, guid: string) => {
    try {
        const userGuid = localStorage.getItem('guid');
        const params: any = Object.assign({}, {
            userGuid: userGuid
        });
        const baseUrl = `${API_URL}/api/work-items/${id}/${guid}`;
        const response: any = await axios.delete(baseUrl, {params});
        return response;
    } catch(e) {
        console.log(e);
    }
}

const getWatchers = async(id: number, guid: string) => {
    try {
        const baseUrl = `${API_URL}/api/work-items/watchers/${id}/${guid}`;
        return await axios.get(baseUrl);
    } catch(e) {
        console.log(e.config);
    }
}

const delteWatcherById = async(id: number, guid: string) => {
    try {
        const baseUrl = `${API_URL}/api/work-items/watchers/${id}/${guid}`;
        return await axios.delete(baseUrl);
    } catch(e) {
        console.log(e.config);
    }
}

const postWatchers = async(watcherList) => {
    try {
        const baseUrl = `${API_URL}/api/work-items/watchers`;
        return await axios.post(baseUrl, watcherList);
    } catch(e) {
        console.log(e.config);
    }
}

const sendWorkItemForm = async(postFormData) => {
    try {
        const baseUrl = `${API_URL}/api/work-items`;
        const config = {     
            headers: { 'content-type': 'multipart/form-data' }
        }
        return await axios.post(baseUrl, postFormData, config);
    } catch(e) {
        console.log(e.config);
    }
}

const editWorkItemForm = async(postFormData) => {
    try {
        const baseUrl = `${API_URL}/api/work-items`;
        const config = {     
            headers: { 'content-type': 'multipart/form-data' }
        }
        return await axios.put(baseUrl, postFormData, config);
    } catch(e) {
        console.log(e.config);
    }
}

const getAllProjects = async() => {
    try {
        const baseUrl = `${API_URL}/api/projects`;
        return await axios.get(baseUrl);
    } catch(e) {
        console.log(e.config);
    }
}

const getByIdWorkItem = async(id: number, guId: string) => {
    try {
        const baseUrl = `${API_URL}/api/work-items/${id}/${guId}`;
        return await axios.get(baseUrl);
    } catch(e) {
        console.log(e.config);
    }
}

const getAttachmentsFromWorkItem = async(id: number, guId: string) => {
    try {
        const baseUrl = `${API_URL}/api/work-items-attachments/work-items/${id}/${guId}`;
        return await axios.get(baseUrl);
    } catch(e) {
        console.log(e.config);
    }
}

const deleteAttachments = async(id: number, guId: string) => {
    try {
        const baseUrl = `${API_URL}/api/work-items-attachments/${id}/${guId}`;
        return await axios.delete(baseUrl);
    } catch(e) {
        console.log(e);
    }
}

const getWorkItemComments = async(id: number, guId: string) => {
    try {
        const baseUrl = `${API_URL}/api/work-item-comments/work-items/${id}/${guId}`;
        const param = {
            commentVisibilityId: 2
        }
        return await axios.get(baseUrl, {params: param});
    } catch(e) {
        console.log(e.config);
    }
}

const postWorkItemComments = async(postComments) => {
    try {
        const baseUrl = `${API_URL}/api/work-item-comments`;
        return await axios.post(baseUrl, postComments);
    } catch(e) {
        console.log(e.config);
    }
}

const deleteWorkItemComments = async(id:number, guid:string) => {
    try {
        const userGuid = localStorage.getItem('guid');
        const baseUrl = `${API_URL}/api/work-item-comments/${id}/${guid}?userGuid=${userGuid}`;
        return await axios.delete(baseUrl);
    } catch(e) {
        console.log(e.config);
    }
}

const assignCopies = async(postWorkItemCopies) => {
    try {
        const baseUrl = `${API_URL}/api/work-items/copies`;
        return await axios.post(baseUrl, postWorkItemCopies);
    } catch(e) {
        console.log(e.config);
    }
}

const notifyUser = async(notifiesUser) => {
    try {
        const baseUrl = `${API_URL}/api/work-items/notifications`;
        return await axios.post(baseUrl, notifiesUser);
    } catch(e) {
        console.log(e.config);
    }
}

const getChildWorkItem = async(workItemId: number, workItemGuid: string) => {
    try {
        const baseUrl = `${API_URL}/api/work-items/child-work-items/${workItemId}/${workItemGuid}`;
        return await axios.get(baseUrl);
    } catch(e) {
        console.log(e.config);
    }
}

const repeatWorkItem = async(repeatItem) => {
    try {
        const baseUrl = `${API_URL}/api/work-items/repeat`;
        return await axios.post(baseUrl, repeatItem);
    } catch(e) {
        console.log(e.config);
    }
}

export {getWorkItemList, 
        deleteWorkItemService, 
        getWatchers,
        editWorkItemForm,
        postWatchers, 
        sendWorkItemForm,
        getAttachmentsFromWorkItem,
        delteWatcherById,
        getAllProjects,
        getWorkItemComments,
        postWorkItemComments,
        deleteWorkItemComments,
        assignCopies,
        notifyUser,
        getByIdWorkItem,
        deleteAttachments,
        getChildWorkItem,
        repeatWorkItem
    };