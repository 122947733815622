import { combineReducers } from 'redux';

import {UIStateReducer} from './UIStateReducer';
import {authReducer} from './AuthReducer';
import { applicationAndUserGroupReducer } from './ApplicationAndUserGroupReducer';
import { workItemReducer } from './WorkItemReducer';
import { errorReducer } from './ErrorReducer';
import { TemplateReducer } from './TemplateReducer';

const rootReducer = combineReducers({
    UIState: UIStateReducer,
    AuthState: authReducer,
    ApplicationAndUserGroup: applicationAndUserGroupReducer,
    WorkItem: workItemReducer,
    Errors: errorReducer,
    Template: TemplateReducer
});

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer;
