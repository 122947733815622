import React from 'react';
import optimusinfologo from '../../Assets/Images/optimusinfologo.png';
import Style from './Loader.module.css';

 
export default function Loader() {
 return (
    <div className ={Style.Loader}>
    <div className="row align-items-center center">
        <img id = {Style.loaderImage} className="logo" src={optimusinfologo}/>
    </div>
</div> 
 )   
}