import React from "react";
import { Days } from "../../Enum/DaysEnum";
import "./WeekdayPicker.css";
export class WeekdayPickerComponent extends React.Component<any,any> {
 
  render() {
    return (
        <div className="weekDays-selector" >
            <input type="checkbox" id="weekday-mon" 
            value = {Days.Monday}
            className="weekday" 
            onChange = {(event: any) => this.props.setValues('weeks', event.target.value)}
            />
            <label htmlFor = "weekday-mon">M</label>
            <input type="checkbox" id="weekday-tue" 
             value = {Days.Tuesday}
            className="weekday"
            onChange = {(event: any) => this.props.setValues('weeks', event.target.value)}
            />
            <label htmlFor="weekday-tue">T</label>
            <input type="checkbox" id="weekday-wed" 
            value = {Days.Wednesday}
            className="weekday"
            onChange = {(event: any) => this.props.setValues('weeks', event.target.value)}
            />
            <label htmlFor="weekday-wed">W</label>
            <input type="checkbox" id="weekday-thu" 
             value = {Days.Thursday}
            className="weekday"
            onChange = {(event: any) => this.props.setValues('weeks', event.target.value)}
            />
            <label htmlFor="weekday-thu">T</label>
            <input type="checkbox" id="weekday-fri" 
             value = {Days.Friday}
            className="weekday"
            onChange = {(event: any) => this.props.setValues('weeks', event.target.value)}
            />
            <label htmlFor="weekday-fri">F</label>
            <input type="checkbox" id="weekday-sat" 
             value = {Days.Saturday}
            className="weekday"
            onChange = {(event: any) => this.props.setValues('weeks', event.target.value)}
            />
            <label htmlFor="weekday-sat">S</label>
            <input type="checkbox" id="weekday-sun" 
             value = {Days.Sunday}
            className="weekday"
            onChange = {(event: any) => this.props.setValues('weeks', event.target.value)}
            />
            <label htmlFor="weekday-sun">S</label>
        </div>
    )
  }
}