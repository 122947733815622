import React from 'react';
import {Table} from "react-bootstrap";
import "./Table.css";
import Button from '../Button/Button';
import { AppConstant } from '../../Constants';
import { Application } from '../../Interfaces/ApplicationListInterface';
import { UserGroup } from '../../Interfaces/UserGroupInterface';

interface Props {
  currentRoute: Number | null,
  setAssignUnassignApplication: (groupId) => void,
  setCurrentUserGroup: () => void,
  setTableData: (groupId) => void,
  tableList: Application[] | UserGroup[],
  type: string
};

export function TableComponent (props) {
  const style = {
    width: '100px'
  }
  return (
  <Table responsive hover size="sm">
    <thead>
    <tr id ="tableHeading">
      <th>#</th>
      <th>{props.currentRoute !== null? AppConstant.APPLICATIONS: AppConstant.USER_GROUP}</th>
      {props.currentRoute !== null?<th className = "textAlignHeader">Assign App</th> : null}
    </tr>
    </thead>
    <tbody id = "tbody">
      {
        props.tableList.map((data, index) => ( <tr key = {index}>
          <td className = "indexRow">{index + 1}</td>
          <td 
            className = "tableClickable">
              {props.currentRoute !== null? 
              <a id = "applicationUrl" target="_blank" href = {data.applicationUrl}>
                {data.applicationName}</a> :
              <span onClick = {() => {props.setTableData(data.groupId);props.setCurrentUserGroup(data.groupId)}}
              >
                {data.groupName}
                </span>
              }
          </td>
          { props.currentRoute !== null? <td className = 'tableClickable textAlign'>
            <Button style = {style}
              size = "sm" 
              name = {data.isAssigned? AppConstant.UN_ASSIGN : AppConstant.ASSIGN}
              onClick = {() => props.setAssignUnassignApplication(data.applicationId)}
            />
          </td> : null}
        </tr>
      ))
    }
  </tbody>
</Table>
        )
}

export default TableComponent;
