import { put, call, select, takeEvery, fork } from 'redux-saga/effects';
import { TemplateList } from '../actions/types';

import {  asyncActionModal, setModalState, setUILoaderState } from '../actions/UIAction';

import { WorkItemStatusEnum } from '../Enum/WorkItemStatus.enum';
import { setErrorMessage } from '../actions/ErrorsAction';
import { callTemplateList, setTemplateList } from '../actions/TemplatesAction';
import { Template } from '../Interfaces/TemplateInterface';
import { deleteTemplateService, getTemplateList, TemplateStatusService } from '../Services/Template/TemplateService';

export function* handleTemplateList(params) {
    try {
        yield put(setUILoaderState(true));
        console.log(params)
        const {filterParams} = params.data;
        console.log(params)
        const TemplateList: Template[] = yield call(getTemplateList, filterParams);
        yield put(setTemplateList(TemplateList));
        yield put(setUILoaderState(false));
    } catch (error) {
        yield put(setUILoaderState(false));
    }
}

export function* handleDeleteTemplate(params) {
    try {
        yield put(setUILoaderState(true));
        const {id, guid, filterParams} = params.data;
        const response: any = yield call(deleteTemplateService, id, guid);
        if(response && response.response) {
            yield put (setErrorMessage(
                {
                    errorMessage: " ",
                    errorMessageHeader: `${response && response.response ? response?.response?.data?.errorCause 
                        : 'Something went wrong'}`
                }
            ))
            yield put(setModalState(true));
            yield put(asyncActionModal(() => {setModalState(false)}))
        }
        yield put(callTemplateList(filterParams));
    } catch (error) {
        yield put(setUILoaderState(false));
        yield put (setErrorMessage(
            {
                errorMessage: " ",
                errorMessageHeader: `${error && error.response ? error.response.errorCause 
                    : 'Something went wrong'}`
            }
        ))
        yield put(setModalState(true));
        yield put(asyncActionModal(() => {setModalState(false)}))
    }
}

export function* handleTemplateStatus(params) {
    try {
        yield put(setUILoaderState(true));
        const {filterParams, changeStatusTemplate} = params.data;
        const response: any = yield call(TemplateStatusService, changeStatusTemplate);
        if(response && response.response) {
            yield put (setErrorMessage(
                {
                    errorMessage: " ",
                    errorMessageHeader: `${response && response.response ? response?.response?.data?.errorCause 
                        : 'Something went wrong'}`
                }
            ))
            yield put(setModalState(true));
            yield put(asyncActionModal(() => {setModalState(false)}))
        }
        yield put(callTemplateList(filterParams));
    } catch (error) {
        yield put(setUILoaderState(false));
        yield put (setErrorMessage(
            {
                errorMessage: " ",
                errorMessageHeader: `${error && error.response ? error.response.errorCause 
                    : 'Something went wrong'}`
            }
        ))
        yield put(setModalState(true));
        yield put(asyncActionModal(() => {setModalState(false)}))
    }
}

export default function* watchTemplateLoad() {
    yield takeEvery(TemplateList.CALL_TEMPLATE, handleTemplateList);
    yield takeEvery(TemplateList.DELETE_TEMPLATE, handleDeleteTemplate);
    yield takeEvery(TemplateList.TEMPLATE_STATUS, handleTemplateStatus);

}
