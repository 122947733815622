import axios from "axios";
import { getJwtToken } from "./Authentication/SignIn";
import { InterceptorConstants, AuthenticationConstants } from "./Constants";

const BASE_URL = '/';
const AUTH_URL = BASE_URL + "auth/login?";
const {  BEARER, JWT_TOKEN, UNAUTHORIZED } = InterceptorConstants;
const {CLIENT_ID} = AuthenticationConstants;

axios.interceptors.request.use((req) => {
      if(!req.url?.includes('Login'))
      req.headers.Authorization = BEARER + localStorage.getItem(`jwt_token`);
  ;
  return req;
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if(error.response.status == 401) {

    }
    return error;
  }
);

export default axios;
