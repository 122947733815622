import TableRows from './TableRows'
import 'bootstrap/dist/css/bootstrap.min.css'
import { useEffect, useState } from 'react'
import React from 'react'
import ButtonComponent from 'Components/Button/Button'
import { Col, Nav, Row } from 'react-bootstrap'
import {
    createAllocation,
    getAllocationResourcesAvailabilityHours,
} from 'Services/AllocationService/AllocationService'
function AddDeleteTableRows({
    resourceData,
    projectResources,
    month,
    year,
    projectId,
}) {
    const [rowsData, setRowsData]: any = useState(resourceData)
    const [message, setMessage]: any = useState('')
    const [showMsg, setShowMsg] = useState(true)
    const [filteredResources, setFilteredResources]: any = useState()

    const saveProjectResource = () => {
        if (!checkValidation()) {
            let createRowsData: any = []
            rowsData.map((data, index) => {
                let resourceObj = {
                    id: data.allocationDetailId ? data.allocationDetailId : 0,
                    allocationId: data.allocationId ? data.allocationId : 0,
                    projectId: projectId,
                    allocatedMonthOneHours: data.month1AllocatedHours,
                    allocatedMonthTwoHours: data.month2AllocatedHours,
                    isDeleted: data.isDeleted,
                }

                createRowsData.push(resourceObj)
            })
            createAllocation(createRowsData)
                .then((response: any) => {
                    setMessage('Data has been updated successfully..')
                })
                .catch((error) => console.log('error'))
            setTimeout(() => {
                setMessage('')
            }, 5000)
        }
    }

    const setButtonStatus = () => {
        if (rowsData.length != 0) {
            const checkStatus = rowsData
                .filter((item) => {
                    return item.isDeleted == false
                })
                .map((data, index) => {
                    return (
                        data.month1AllocatedHours &&
                        data.month2AllocatedHours &&
                        data.resourceMailId
                    )
                })

            return !checkStatus.every(Boolean)
        }
        return true
    }

    const checkValidation = () => {
        let valueArr = rowsData
            .filter((item) => {
                return item.isDeleted == false
            })
            .map((item) => {
                return item.resourceMailId
            })
        let isDuplicate = valueArr.some(function (item, idx) {
            return valueArr.indexOf(item) != idx
        })
        const checkStatus = rowsData
            .filter((item) => {
                return item.isDeleted == false
            })
            .map((data, index) => {
                if (!data.isDeleted) {
                    return (
                        data.month1AllocatedHours >
                            data.month1AvailabilityHours ||
                        data.month2AllocatedHours >
                            data.month2AvailabiltyHours ||
                        data.month1AllocatedHours < 0 ||
                        data.month2AllocatedHours < 0
                    )
                }
            })

        const hourStatus = checkStatus.includes(true)
        if (isDuplicate && !hourStatus) {
            setMessage('Please remove duplicate resources')
        } else if (!isDuplicate && hourStatus) {
            setMessage(
                'Allocated hours can not be more than available hours and less than 0'
            )
        } else if (isDuplicate && hourStatus) {
            setMessage(
                'Please remove duplicate resources and  allocated hours should be within range of 0 to available hours'
            )
        }
        setTimeout(() => {
            setMessage('')
        }, 5000)

        return isDuplicate || hourStatus
    }

    const addTableRows = () => {
        const rowsInput = {
            resourceMailId: '',
            month1AvailabilityHours: '',
            month2AvailabiltyHours: '',
            month1AllocatedHours: '',
            month2AllocatedHours: '',
            isEditable: true,
            isDeleted: false,
            allocationDetailId: '',
            allocationId: '',
        }
        setRowsData([...rowsData, rowsInput])
    }
    const deleteTableRows = (index) => {
        const rows = [...rowsData]

        const updatedRows = rows.map((row, id) => {
            if (id == index) {
                return { ...row, isDeleted: true }
            }
            return row
        })
        setRowsData(updatedRows)
    }

    const handleChange = (index, evnt) => {
        const { name, value } = evnt.target
        if (name == 'resourceMailId' && value == 'default') {
            const rowsInput = [...rowsData]
            rowsInput[index][name] = value
            rowsInput[index]['month1AvailabilityHours'] = ''
            rowsInput[index]['month2AvailabiltyHours'] = ''
            rowsInput[index]['allocationId'] = ''

            setRowsData(rowsInput)
            return
        }

        if (name == 'resourceMailId') {
            const rowsInput = [...rowsData]
            rowsInput[index][name] = value

            getAllocationResourcesAvailabilityHours(month, year, value)
                .then((response: any) => {
                    rowsInput[index]['month1AvailabilityHours'] =
                        response.data.month1AvailabilityHours
                    rowsInput[index]['month2AvailabiltyHours'] =
                        response.data.month2AvailabiltyHours
                    rowsInput[index]['allocationId'] =
                        response.data.allocationId

                    setRowsData(rowsInput)
                })
                .catch((error) => console.log('error'))
        } else {
            const rowsInput = [...rowsData]
            rowsInput[index][name] = value
            setRowsData(rowsInput)
        }
    }
    return (
        <div className="container">
            <div className="right-holder">
                {message}
                <Nav.Item
                    id="button-nav-project"
                    style={{ marginRight: '17rem' }}
                >
                    <Row>
                        <Col>
                            <ButtonComponent
                                style={{
                                    fontSize: '14px',

                                    padding: '30px',
                                }}
                                name={'Save'}
                                disabled={setButtonStatus()}
                                onClick={() => saveProjectResource()}
                            />
                        </Col>
                    </Row>
                </Nav.Item>
            </div>
            <div className="row">
                <div className="col-sm-8">
                    <table className="table">
                        <thead>
                            <tr>
                                <th style={{ width: '40%' }}>Resource</th>
                                <th>Available Month1 </th>
                                <th>Available Month2</th>
                                <th>Allocate Month1</th>
                                <th>Allocate Month2</th>
                                <th>
                                    <button
                                        className="btn btn-outline-success"
                                        onClick={addTableRows}
                                    >
                                        +
                                    </button>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <TableRows
                                rowsData={rowsData}
                                deleteTableRows={deleteTableRows}
                                handleChange={handleChange}
                                projectResources={projectResources}
                            />
                        </tbody>
                    </table>
                </div>
                <div className="col-sm-4"></div>
            </div>
        </div>
    )
}
export default AddDeleteTableRows
