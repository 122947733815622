import React from 'react'
import { Tabs, Tab, Row, Col } from 'react-bootstrap'
import AddDeleteTableRows from './AddDeleteTableRows'

import AllocationPrimary from './AllocationPrimary'
import AllocationProjects from './AllocationProjects'
import AllocationResource from './AllocationResource'
import AllocationSkillResources from './AllocationSkillResources'
function AllocationContainer() {
    return (
        <React.Fragment>
            <Tabs
                defaultActiveKey="listOfResources"
                id="uncontrolled-tab-example"
            >
                <Tab
                    eventKey="listOfResources"
                    title="Dept. Wise"
                    statusSet={new Set([])}
                >
                    <div id="firstTab">
                        <AllocationResource />
                    </div>
                </Tab>

                {/* localStorage.getItem('adminRole') != 'Admin' ? null : ( */}
                {/* <Tab eventKey="assignedTickets" title="Allocation Primary">
                    <div id="secondTab">
                        <AllocationPrimary />
                    </div>
                </Tab> */}

                <Tab eventKey="assignedTickets2" title="Skill Wise">
                    <div id="secondTab">
                        <AllocationSkillResources />
                    </div>
                </Tab>
                <Tab eventKey="assignedTickets3" title="Allocation">
                    <div id="thirdTab">
                        <AllocationProjects />
                    </div>
                </Tab>
            </Tabs>
        </React.Fragment>
    )
}

export default AllocationContainer
