import { put, call, select, takeEvery, fork } from 'redux-saga/effects';
import { applicationAndUserGroupList } from '../actions/types';
import {getApplicationList, getUserApplicationList, getUserGroupList, postApplicationList} from '../Services/ApplicationAndUserGroup/ApplicationAndUserService';
import { setApiResponseFlag, setModalState, setUILoaderState } from '../actions/UIAction';
import { setUserGroupListApi, setApplicationListApi, setDasboardApplication } from '../actions/ApplicationAndUserGroupAction';
import { AuthState } from '../Interfaces/AuthConfiginterface';
import { authProvider } from '../Services/Authentication/AuthProvider';
import { AppConstant } from '../Constants';
import { AuthenticationConstants } from '../Services/Constants';
import { setAuthData } from '../actions/AuthenticationAction';

export function* handleApplicationList(userGroupId) {
    try {
        yield put(setUILoaderState(true));
        const listId = userGroupId.data.data;
        const applicationList = yield call(getApplicationList, listId);
        yield put(setUILoaderState(false));
        yield put(setApplicationListApi(applicationList));
    } catch (error) {
        yield put(setApplicationListApi([]));
        yield put(setUILoaderState(false));
    }
}

export function* handleUserGroupList() {
    try {
        yield put(setUILoaderState(true));
        const userList = yield call(getUserGroupList);
        yield put(setUserGroupListApi(userList));
        yield put(setUILoaderState(false));
    } catch (error) {
        yield put(setUserGroupListApi([]));
        yield put(setUILoaderState(false));
    }
}

export function* handlePostApplicationList(action) {
    try {
        yield put(setUILoaderState(true));
        const images = yield call(postApplicationList, action.data.data);
        yield put(setUILoaderState(false));
    } catch (error) {
        yield put(setUILoaderState(false));
    }
}

export function* handleUserApplicationList(data) {
    try {
        yield put(setUILoaderState(true));
        const userId = data.data.data;
        const applicationList = yield call(getUserApplicationList, userId);
        if(applicationList === undefined) {
            yield put(setDasboardApplication([]));
                const accountObject: any = authProvider.getAccountInfo();
                const {userName} = accountObject[AppConstant.ACCOUNT];
                const {CLIENT_ID} = AuthenticationConstants; 
                const accessToken = localStorage.getItem(`msal.${CLIENT_ID}.idtoken`);
                const role = JSON.parse(JSON.stringify(localStorage.getItem(AppConstant.ROLE)));
                let authState: AuthState = {
                    accessToken: accessToken || '',
                    userId: localStorage.getItem(AppConstant.USER_ID) || 0,
                    userName: userName,
                    role:role || [],
                    isAuthenticated: false
                  };
            yield put(setAuthData(authState));
        } else {
            yield put(setDasboardApplication(applicationList));
        }
        yield put(setUILoaderState(false));
        yield put(setApiResponseFlag(true));
    } catch (error) {
        yield put(setDasboardApplication([]));
        yield put(setUILoaderState(false));
    }
}

export default function* watchApplicationAndUserListLoad() {
    yield takeEvery(applicationAndUserGroupList.CALL_USER_GROUP, handleUserGroupList);
    yield takeEvery(applicationAndUserGroupList.CALL_APPLICATION, handleApplicationList);
    yield takeEvery(applicationAndUserGroupList.CALL_USER_APPLICATION, handleUserApplicationList);
    yield takeEvery(applicationAndUserGroupList.POST_APPLICATION_LIST, handlePostApplicationList);

}
