import ButtonComponent from 'Components/Button/Button'
import { AppConstant, ButtonName, Routes } from 'Constants'
import React, { useEffect, useState } from 'react'
import { Col, Nav, OverlayTrigger, Popover, Row, Table } from 'react-bootstrap'
import { getUsersList } from 'Services/ResourceService/ResourceService'
import { useHistory } from 'react-router-dom'
import { getSkillWiseEmployeeCount } from 'Services/AllocationService/AllocationService'
function AllocationSkillResources() {
    const [ResourceSkillData, setResourceSkillData]: any = useState([])
    const [searchTerm, setSearchTerm] = useState('')
    const [filteredResults, setFilteredResults] = useState([])
    const [uiLoader, setUiLoader] = useState(true)
    const history = useHistory()

    const roleName = localStorage.getItem('adminRole')

    useEffect(() => {
        getSkillWiseEmployeeCount()
            .then((response: any) => {
                setResourceSkillData(response.data.result)
                setUiLoader(false)
            })
            .catch((error) => console.log('error'))
    }, [])

    const editItem = (userPrincipalName, userId) => {
        history.push({
            pathname: Routes.RESOURCE_MANAGER_EDIT,
            state: { userPrincipalName: userPrincipalName, userId: userId },
        })
    }
    const renderResources = (userData) => {
        return (
            <React.Fragment>
                <tr>
                    <td>
                        <span>{userData.skill || '-'}</span>
                    </td>
                    <td>
                        <span>{userData.contractor || '0'}</span>
                    </td>

                    <td>{userData.regular || '0'}</td>
                    <td>{userData.total || '0'}</td>
                </tr>
            </React.Fragment>
        )
    }

    const searchData = (value) => {
        setSearchTerm(value)
        if (searchTerm !== '') {
            const filteredData = ResourceSkillData.filter((item) => {
                return Object.values(item)
                    .join('')
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase())
            })
            setFilteredResults(filteredData)
        } else {
            setFilteredResults(ResourceSkillData)
        }
    }
    return uiLoader || roleName != 'Admin' ? (
        <div className="loader-list">Loading....</div>
    ) : (
        <div>
            <React.Fragment>
                {ResourceSkillData ? (
                    <div className="table-allocation">
                        <Table responsive hover size="sm">
                            <thead>
                                <tr id="tableHeading">
                                    <th style={{ width: '20rem' }}>Skill</th>
                                    <th style={{ width: '20rem' }}>
                                        Contractor
                                    </th>
                                    <th style={{ width: '20rem' }}>Regular</th>
                                    <th style={{ width: '20rem' }}>
                                        Grand Total
                                    </th>
                                </tr>
                            </thead>
                            <tbody id="tbody">
                                {ResourceSkillData.employeeSkillList.map(
                                    (userData, index) => {
                                        return renderResources(userData)
                                    }
                                )}
                            </tbody>
                        </Table>
                        <Table responsive hover size="sm">
                            <thead>
                                <tr id="tableHeading">
                                    <th style={{ width: '20rem' }}>
                                        Grand Total
                                    </th>
                                    <th style={{ width: '20rem' }}>
                                        {ResourceSkillData.totalContractor}
                                    </th>
                                    <th style={{ width: '20rem' }}>
                                        {ResourceSkillData.totalRegular}
                                    </th>
                                    <th style={{ width: '20rem' }}>
                                        {ResourceSkillData.grandTotal}
                                    </th>
                                </tr>
                            </thead>
                        </Table>
                    </div>
                ) : (
                    <div style={{ color: 'red', fontSize: '21px' }}>
                        Please try again
                    </div>
                )}
            </React.Fragment>
        </div>
    )
}

export default AllocationSkillResources
