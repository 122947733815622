import React from 'react'
import './Pagination.css'

interface IProps {
    totalRecords: number
    limit: number
    handleValueChange: any
    currentPage: number
}

interface IState {
    page: number
    totalPages: number
    selectedPage: number
}

class Pagination extends React.Component<IProps, IState> {
    constructor(props: Readonly<IProps>) {
        super(props)
        this.state = {
            page: 1,
            totalPages: 0,
            selectedPage: 1,
        }
    }

    componentDidMount() {
        this.getTotalPages()
    }

    getTotalPages = () => {
        const totalPages = Math.ceil(this.props.totalRecords / this.props.limit)
        this.setState({ totalPages })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.totalRecords !== this.props.totalRecords) {
            this.getTotalPages()
        }
        if (prevState.selectedPage !== this.state.selectedPage) {
            this.props.handleValueChange(this.state.selectedPage)
        }
        if(prevProps.currentPage !== this.props.currentPage && this.props.currentPage === 1){
            this.setState({ selectedPage: 1})
        }
    }

    renderPages = () => {
        let renderPages: any[] = []
        const { selectedPage, totalPages } = this.state
        if (totalPages < 10) {
            for (let i = 0; i < totalPages; i++) {
                let page = i + 1
                renderPages.push(
                    <button
                        key={page}
                        onClick={() => {
                            this.pageChange(page)
                        }}
                        className={
                            this.state.selectedPage === page
                                ? 'selected-page'
                                : ''
                        }
                    >
                        {page}
                    </button>
                )
            }
        } else {
            for (let i = 0; i < 5; i++) {
                let page = i + 1
                renderPages.push(
                    <button
                        key={page}
                        onClick={() => {
                            this.pageChange(page)
                        }}
                        className={
                            this.state.selectedPage === page
                                ? 'selected-page'
                                : ''
                        }
                    >
                        {page}
                    </button>
                )
            }
            if (selectedPage > 5 && selectedPage < totalPages) {
                if (selectedPage > 5 + 1) {
                    renderPages.push(<span key={5 + 1}>...</span>)
                }
                renderPages.push(
                    <button
                        key={selectedPage}
                        onClick={() => {
                            this.pageChange(selectedPage)
                        }}
                        className={
                            this.state.selectedPage === selectedPage
                                ? 'selected-page'
                                : ''
                        }
                    >
                        {selectedPage}
                    </button>
                )
                if (selectedPage < totalPages - 1) {
                    renderPages.push(<span key={totalPages - 1}>...</span>)
                }
            } else {
                renderPages.push(<span key={5 + 1}>...</span>)
            }
            renderPages.push(
                <button
                    key={totalPages}
                    onClick={() => {
                        this.pageChange(totalPages)
                    }}
                    className={
                        this.state.selectedPage === totalPages
                            ? 'selected-page'
                            : ''
                    }
                >
                    {totalPages}
                </button>
            )
        }

        return renderPages
    }

    pageChange = (page) => {
        this.setState({ selectedPage: page })
    }

    handlePrevious = () => {
        const { selectedPage } = this.state
        this.setState({
            selectedPage: +selectedPage - 1 ? +selectedPage - 1 : selectedPage,
        })
    }

    handleNext = () => {
        const { selectedPage } = this.state
        this.setState({
            selectedPage:
                +selectedPage + 1 <= this.state.totalPages
                    ? +selectedPage + 1
                    : this.state.selectedPage,
        })
    }

    handleFirst = () => {
        this.setState({
            selectedPage: 1,
        })
    }

    handleLast = () => {
        this.setState({
            selectedPage: this.state.totalPages,
        })
    }

    render() {
        return (
            <div className="Pagination">
                <div className="pagination">
                    <div className="total-records">
                        TotalRecords:
                        <span>{` ${this.props.totalRecords}`}</span>
                    </div>
                    <div className="render-pages">
                        {/* <button onClick={() => this.handleFirst()}>first</button> */}
                        <button onClick={() => this.handlePrevious()}>
                            prev
                        </button>
                        <span className="pages">{this.renderPages()}</span>
                        <button onClick={() => this.handleNext()}>next</button>
                        {/* <button onClick={() => this.handleLast()}>last</button> */}
                    </div>
                </div>
            </div>
        )
    }
}

export default Pagination
