type selectModel = {
    id: number,
    name: string,
    guid?: string
}

const selectObject: selectModel = {
    id: 0,
    name: ''
}
export class FillTemplateModel {
    template: selectModel;
    categoryId: number;
    subcategoryId: number;
    category: selectModel;
    subCategory: selectModel;
    constructor() {
        this.template = selectObject;
        this.category = selectObject;
        this.subCategory = selectObject;
    }
}

