import React, { useEffect, useState } from 'react';
import { Col, Container, Dropdown, DropdownButton, Form, Modal, Row } from 'react-bootstrap';
import './SetRepeat.css';
import {resolveModalPromise, setModalState, asyncActionModal} from '../../actions/UIAction';
import { connect } from 'react-redux';
import { ButtonComponent } from '../Button/Button';
import { WeekdayPickerComponent } from '../Weekday-Picker/WeekdayPicker';
import { TimePeriodEnum } from '../../Enum/TimePeriodTypeEnum';
import { TimePeriodMonthEnum } from '../../Enum/TimePeriodMonthEnum';
import { repeatWorkItem } from '../../Services/WorkItemAndTaskService/WorkItemList';
import moment from 'moment';

function RecurrenceModal(props) {
    const days: any[] = [];
    const [awaiter, setawaiter] = useState<any>();
    const [occurenceType, setOccurenceType] = useState('Days');
    const [monthType, setmonthType] = useState('');
    const [dayName, setDayName] = useState('');
    const [dateValue, setDateValue] = useState(0);
    const [occurance, setOccurance] = useState({
        daysOfWeek: days,
        endDate: moment(new Date()).format("YYYY-MM-DD"),
        interval: 1,
        isRepeatSameWeekDayOfMonth: false,
        occurrence: 1,
        timePeriodType: 1,
        workItemId: props.workItemId,
        isEndsOn: true
    });

    useEffect(() => {
        setinitialOccurance(occurance);
        setDayName(getNth(props.startDate));
        setDateValue(props.startDate.split('-')[2]);
        setmonthType(`Monthly on day ${props.startDate.split('-')[2]}`);
    }, [props.startDate])

    function getNth(dat) {
        let days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday','saturday'],
            nth  = ['st', 'nd', 'rd', 'th', 'th'],
            d    = dat ? dat instanceof Date ? dat : new Date(dat) : new Date(),
            date = d.getDate(),
            day  = d.getDay(),
            n    = Math.ceil(date / 7);
        
        return n === 5 ?  'last' + ' ' + days[day] : n + nth[n-1] + ' ' + days[day];
    }

    const [initialOccurance, setinitialOccurance] = useState(occurance)

    function setValues(type, value: any) {
        if(type === 'timePeriodType') {            
            if(+TimePeriodEnum[value] !== 3) {
                const newOccurance = {...occurance};
                newOccurance.daysOfWeek = []
            }
            const newOccurance = {...occurance, timePeriodType: +TimePeriodEnum[value]};
            setOccurance(newOccurance);
        } else if(type === 'interval') {
            const newOccurance = {...occurance, interval: +value};
            setOccurance(newOccurance);
        } else if(type === 'occurrance'){
            const newOccurance = {...occurance, occurrence: +value};
            setOccurance(newOccurance);
        } else if(type === "endDate") {
            const newOccurance = {...occurance, endDate: value};
            setOccurance(newOccurance);
        } else if(type === 'monthSelect') {
            const newOccurance = {...occurance};
            value.includes('days') ? newOccurance.isRepeatSameWeekDayOfMonth = false 
            : newOccurance.isRepeatSameWeekDayOfMonth = true; 
            setOccurance(newOccurance);
        } else if(type === 'weeks') {
            const newOccurance = {...occurance};
            const currDayIndex = newOccurance.daysOfWeek.findIndex(day => {
                return day == value;
            });
            const monthValue: never | any = +value;
            currDayIndex === -1 ? newOccurance.daysOfWeek.push(monthValue) 
            : delete newOccurance.daysOfWeek[currDayIndex]
        }
    }

    function setEndsOn(value) {
        if(occurance.isEndsOn !== value) {
            const newOccurance = {...occurance, isEndsOn: value};
            setOccurance(newOccurance);
        }
    }

    const {Errors: {errorMessage: {errorMessage, errorMessageHeader}}} = props;

    const selectType = [`${occurenceType}`].map((data, index) => <DropdownButton
        className = "align-dropdown assign-title-center dropdown-font"
        key={"secondary"}
        id={`dropdown-variants-secondary`}
        variant={"secondary"}
        title={occurenceType}
        >
    {['Days', 'Months', 'Weeks'].map((data, index) => {
        return <Dropdown.Item
            key = {index}
            eventKey = {`${index + 1}`}
            onClick = {() => {setOccurenceType(data);setValues('timePeriodType',data)}}
        >
        {data}
        </Dropdown.Item>
    })}
    </DropdownButton>);

const selectMonthType = [`${monthType}`].map((data, index) => <DropdownButton
    className = "align-dropdown assign-title-center dropdown-font"
    key={"secondary"}
    id={`dropdown-variants-secondary`}
    variant={"secondary"}
    title={`${monthType}`}
>
{[`Monthly on day ${props.startDate.split('-')[2]}`, 
    `Monthly on the ${dayName}`].map((data, index) => {
return <Dropdown.Item
    key = {index}
    eventKey = {`${index + 1}`}
    onClick = {() => {setValues('monthSelect', data); setmonthType(data)}}
>
{data}
</Dropdown.Item>
})}
</DropdownButton>);

    const displayOccuranceType = () => {
        if(occurenceType === "Days") {
            return null;
        } else if(occurenceType === "Months") {

            return <Row className = "mt-3">
                <Col md = {10}>
                    {selectMonthType}
                </Col>
            </Row>
        } else if(occurenceType === "Weeks"){
            return <Row className = "mt-3">
            <Col md = {10}>
                <label>
                    Repeat On
                </label>
                <WeekdayPickerComponent setValues = {setValues}/>
            </Col>
        </Row>
        }
    }

    const sendWorkItemRepeat = () => {
        const postRepeat: any = {};
        postRepeat['timePeriodType'] = occurance.timePeriodType;
        postRepeat['workItemId'] = props.workItemId;
        postRepeat['interval'] = occurance.interval;
        if(occurance.timePeriodType === 1) {
            postRepeat['isRepeatSameWeekDayOfMonth'] = false;
            if(occurance.isEndsOn) {
                postRepeat['endDate'] = occurance.endDate;
            } else {
                postRepeat['occurrence'] = occurance.occurrence;
            }
        } else if(occurance.timePeriodType === 2) {
            postRepeat['isRepeatSameWeekDayOfMonth'] = occurance.isRepeatSameWeekDayOfMonth;
            if(occurance.isEndsOn) {
                postRepeat['endDate'] = occurance.endDate;
            } else {
                postRepeat['occurrence'] = occurance.occurrence;
            }
        } else {
            postRepeat['isRepeatSameWeekDayOfMonth'] = false;
            postRepeat['daysOfWeek'] = occurance.daysOfWeek;
            if(occurance.isEndsOn) {
                postRepeat['endDate'] = occurance.endDate;
            } else {
                postRepeat['occurrence'] = occurance.occurrence;
            }
        }
        closeModal();
        repeatWorkItem(postRepeat)
        .then(() => {
            setOccurance(initialOccurance);
            
            props.goToTaskList();
        })
    }

    function closeModal() {
        setOccurance(initialOccurance);
        props.onHide(props.thisRef)
    }
    
    return (
      <Modal
        {...props}
        size="lg"
        show = {props.showModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="modal-50w"
        id = "occuranceModal"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
          Set Recurrence
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
              <Form id = "repeat-form">
            <Row className = "mt-3">
                <Col md = {3}>
                    <span>
                        Repeat every
                    </span>
                </Col>
                <Col md = {{span: 3, offset: 1}}>
                    <div>
                    <Form.Control value = {occurance.interval}
                    min="1"
                    type = 'number' onChange ={(event) => {
                        setValues('interval', event.target.value)
                    }}/>
                    </div>
                </Col>
                <Col md = {3}>
                    {selectType}
                </Col>
            </Row>
            {displayOccuranceType()}
            <div className = "mt-2 mb-2">End</div>
            <Row className ="mt-3 text-center">
                <Col md = {4}>
                <input  
                    className = "input-radio" defaultChecked  
                    onClick = {() => setEndsOn(true)}
                    type = 'radio' 
                    name = "occuranceRadio" 
                />
                <label >On</label><br></br>
                </Col>
                <Col md = {6}>
                <Form.Control type = 'date' 
                value = {occurance.endDate} 
                onChange = {(event) => {
                    setValues('endDate', event.target.value)
                }}/>
                </Col>
            </Row>
            <Row className ="mt-3 text-center">
                <Col md = {4}>
                <input  
                    className = "input-radio-after" type = 'radio'  
                    onClick = {() => setEndsOn(false)}
                    name = "occuranceRadio"
                />
                <label>After</label><br></br>
                </Col>
                <Col md = {3}>
                <Form.Control type = 'number'
                value = {occurance.occurrence}
                min="1"
                onChange = {(event) => {
                    setValues('occurrance', event.target.value)
                }}/>
                </Col>
                <Col md = {3}>
                <Form.Control value = "Occurences" readOnly/>
                </Col>
            </Row>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <ButtonComponent style = {{width: '80px'}}
            size = 'sm' onClick={() => closeModal()}
            name ={'Cancel'}/>
        <ButtonComponent style = {{width: '80px'}}
            size = 'sm' onClick={() => sendWorkItemRepeat()}
            name ={'Ok'}/>
        </Modal.Footer>
      </Modal>
    );
  }

const mapStateToProps = (UIState, ApplicationAndUserGroup) => {
    return UIState;
};

const mapDispatchToProps = (dispatch) => ({
    asyncActionModal: (data) => dispatch(asyncActionModal(data)),
    setModalState: (data) => dispatch(setModalState(data))
  });

export default connect(mapStateToProps, mapDispatchToProps)(RecurrenceModal);
