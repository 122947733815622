import React, { Component } from 'react'
import { Row, Col, Card, Form, Modal } from 'react-bootstrap'
import axios from 'axios'
import RichTextEditor from 'react-rte'
import ButtonComponent from 'Components/Button/Button'
import { API_URL, deptNameWithId, classWithId } from '../../Services/Constants'
import MyStatefulEditor from '../../Components/Text-Editor/TextEditor'
import { ButtonName } from '../../Constants'
import './CreateSupportTicket.css'
import { Category, Priority } from '../../Interfaces/CategoryInterface'

interface State {
    formData: FormData
    fileName: any
    categories?: Array<Category> | null
    priorities?: Array<Priority> | null
    error: boolean
    errorMessage: string
}

interface FormData {
    body: any
    subject: string
    supportTicketDepartmentId: any
    supportTicketCategoryId: any
    supportTicketPriorityId: any
    supportTicketClassificationId: any
    userId?: any
    fileData: any
}

const userId = localStorage.getItem('userId')

export class CreateSupportTicket extends Component<any, State> {
    fileRef: any
    constructor(props: any) {
        super(props)
        this.fileRef = React.createRef()
        this.state = {
            formData: {
                body: RichTextEditor.createEmptyValue(),
                subject: '',
                supportTicketDepartmentId: 1,
                supportTicketCategoryId: 1,
                supportTicketPriorityId: 1,
                supportTicketClassificationId: 1,
                userId: userId,
                fileData: '',
            },
            fileName: '',
            priorities: [...deptNameWithId[0]['priorities']],
            categories: [...deptNameWithId[0]['categories']],
            error: false,
            errorMessage:
                'Oops! Something went wrong! Could not create the ticket!',
        }
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.uploadFilesData = this.uploadFilesData.bind(this)
    }

    submitCreateFormData() {
        const formData = new FormData()
        const data = {
            ...this.state.formData,
            Subject: this.state.formData.subject,
            SupportTicketDepartmentId: this.state.formData
                .supportTicketDepartmentId,
            SupportTicketCategoryId: this.state.formData
                .supportTicketCategoryId,
            SupportTicketPriorityId: this.state.formData
                .supportTicketPriorityId,
            SupportTicketClassificationId: this.state.formData
                .supportTicketClassificationId,
            UserId: this.state.formData.userId,
        }
        Object.keys(data).forEach((key) => {
            if (key !== 'body') {
                formData.append(key, data[key])
            }
            return true
        })

        const bodyVal = data.body.toString('html')
        formData.append('Body', bodyVal)
        const baseUrl = `${API_URL}/supports`
        axios
            .post(baseUrl, formData)
            .then((resp) => {
                if (resp.status !== 200) {
                    this.setState({
                        error: true,
                    })
                    return
                }
                // this.setState({
                //     formData: {
                //         Body: RichTextEditor.createEmptyValue(),
                //         Subject: '',
                //         supportTicketDepartmentId: 1,
                //         SupportTicketCategoryId: 1,
                //         SupportTicketPriorityId: 1,
                //         SupportTicketClassificationId: 1,
                //         UserId: userId,
                //         fileData: new FormData(),
                //     },
                //     // doubt
                //     categories: YVRAdminAndLogisticSupportCategories,
                // })
                this.props.history.push('/supportTicket')
            })
            .catch((error) => {
                this.setState({ error: true })
            })
    }

    handleInputChange(event) {
        event.preventDefault()
        this.setState({
            formData: {
                ...this.state.formData,
                [event.target.name]: event.target.value,
            },
        })
    }

    handleChange(event, array) {
        let store = array.filter((data) => {
            if (+data[Object.keys(data)[0]] === +event.target.value) {
                return data
            }
            return false
        })

        let key = Object.keys(store[0])[0]
        this.setState({
            formData: { ...this.state.formData, [key]: event.target.value },
        })
    }

    handleDepartmentChange(event, array) {
        if (isFinite(event.target.value)) {
            if (+event.target.value === 4) {
                this.setState({
                    formData: {
                        ...this.state.formData,
                        supportTicketDepartmentId: 4,
                        supportTicketCategoryId: '',
                    },
                    categories: null,
                    priorities: deptNameWithId[3]['priorities'],
                })
            } else {
                this.setState({
                    formData: {
                        ...this.state.formData,
                        supportTicketDepartmentId: event.target.value,
                    },
                    categories: [
                        ...array[event.target.value - 1]['categories'],
                    ],
                    priorities: [
                        ...array[event.target.value - 1]['priorities'],
                    ],
                })
            }
        }
    }

    uploadFilesData(event) {
        event.target.files[0] &&
            this.setState({
                formData: {
                    ...this.state.formData,
                    [event.target.name]: event.target.files[0],
                },
                fileName: event.target.files[0].name,
            })
    }

    afterFileUpload() {
        // TODO: on click upload functionality after choosing file
    }

    handleRemoveFile = () => {
        this.fileRef.current.value = null
        this.setState({
            formData: {
                ...this.state.formData,
                fileData: '',
            },
            fileName: '',
        })
    }

    fileUpload = () => {
        this.fileRef.current.click()
    }

    onChangeDescription(curRef, description: any, value) {
        curRef.setState(
            { formData: { ...curRef.state.formData, body: value } },
            () => {
                console.log(curRef.state)
            }
        )
    }

    onClickCancel() {
        this.setState({
            formData: {
                body: RichTextEditor.createEmptyValue(),
                subject: '',
                supportTicketDepartmentId: 1,
                supportTicketCategoryId: 1,
                supportTicketPriorityId: 1,
                supportTicketClassificationId: 1,
                fileData: new FormData(),
            },
        })

        this.props.history.push('/supportTicket')
    }

    /**
     *
     * @param html takes html with inner context as string
     * @returns only the innerHTML from the html string
     */
    checkHtmlText(html: string) {
        return html ? html.trim().replace(/<[^>]+>/g, '') : ''
    }

    render() {
        return (
            <Form>
                <Modal
                    style={{
                        zIndex: 50000,
                        marginTop: '35vh',
                    }}
                    show={this.state.error}
                    onHide={() => {
                        this.setState({ error: false })
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Error</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{this.state.errorMessage}</Modal.Body>
                </Modal>
                <Row className="mt-3 mb-3">
                    <Col md={9}>
                        <h5>Create Support Ticket</h5>
                    </Col>
                    <Col
                        md={3}
                        className="form-action-button-container ml-auto"
                    >
                        <div className="save-button">
                            <ButtonComponent
                                name={ButtonName.SAVE}
                                style={{
                                    width: '80px',
                                }}
                                onClick={() => this.submitCreateFormData()}
                            />
                        </div>
                        <div>
                            <ButtonComponent
                                name={ButtonName.CANCEL}
                                style={{
                                    width: '80px',
                                }}
                                onClick={() => this.onClickCancel()}
                            />
                        </div>
                    </Col>
                </Row>
                <Card className="shadow p-3 mb-5 bg-white rounded">
                    <Card.Body className="card-body">
                        <Row className="pl-3">
                            <label className="form-control-label">
                                Department *
                            </label>
                            <select
                                className="set-input-width  mb-4"
                                onChange={(event) => {
                                    this.handleDepartmentChange(
                                        event,
                                        deptNameWithId
                                    )
                                }}
                                name={
                                    this.state.formData
                                        .supportTicketDepartmentId
                                }
                            >
                                {deptNameWithId.map((item: any, i) => (
                                    <option
                                        key={i}
                                        value={item.supportTicketDepartmentId}
                                    >
                                        {item.departmentName}
                                    </option>
                                ))}
                            </select>
                            {this.state.categories ? (
                                <>
                                    <label className="form-control-label">
                                        Category *
                                    </label>
                                    <select
                                        className="set-input-width  mb-4"
                                        onChange={(event) => {
                                            return this.handleChange(
                                                event,
                                                this.state.categories
                                            )
                                        }}
                                        name={
                                            this.state.formData
                                                .supportTicketCategoryId
                                        }
                                    >
                                        {this.state.categories?.map(
                                            (item: Category, i) => (
                                                <option
                                                    key={i}
                                                    value={
                                                        item.supportTicketCategoryId
                                                    }
                                                >
                                                    {item.categoryName}
                                                </option>
                                            )
                                        )}
                                    </select>
                                </>
                            ) : null}

                            <label className="form-control-label">
                                Subject *
                            </label>
                            <Form.Control
                                className="set-input-width  mb-4"
                                name="subject"
                                value={this.state.formData.subject}
                                placeholder=""
                                onChange={this.handleInputChange}
                            />

                            <label className="form-control-label mt-2">
                                Description *
                            </label>
                            <div id="text-editor-container">
                                <MyStatefulEditor
                                    onChange={this.onChangeDescription}
                                    curRef={this}
                                    name="body"
                                    value={this.state.formData.body}
                                />
                            </div>
                            <label className="form-control-label mt-4">
                                Classifications *
                            </label>
                            <select
                                className="set-input-width  mb-4"
                                onChange={(event) =>
                                    this.handleChange(event, classWithId)
                                }
                                name={
                                    this.state.formData
                                        .supportTicketClassificationId
                                }
                            >
                                {classWithId.map((item: any, i) => (
                                    <option
                                        key={i}
                                        value={
                                            item.supportTicketClassificationId
                                        }
                                    >
                                        {item.classificationName}
                                    </option>
                                ))}
                            </select>
                            <label className="form-control-label">
                                Priority *
                            </label>
                            <select
                                className="set-input-width  mb-4"
                                onChange={(event) =>
                                    this.handleChange(
                                        event,
                                        this.state.priorities
                                    )
                                }
                                name={
                                    this.state.formData.supportTicketPriorityId
                                }
                            >
                                {this.state.priorities?.map(
                                    (item: Priority, i) => (
                                        <option
                                            key={i}
                                            value={item.supportTicketPriorityId}
                                        >
                                            {item.priorityName}
                                        </option>
                                    )
                                )}
                            </select>
                        </Row>
                    </Card.Body>
                </Card>
                <Card className="shadow p-3 mb-5 bg-white rounded">
                    <Card.Body>
                        <Row>
                            <Col lg={5}>
                                <span>Attachments</span>
                            </Col>
                            <Col lg={7} className="align-last">
                                <form
                                    className="form-inline"
                                    encType="multipart/form-data"
                                >
                                    <input
                                        type="file"
                                        name="fileData"
                                        ref={this.fileRef}
                                        className="mt-2 file-upload"
                                        onChange={(event) =>
                                            this.uploadFilesData(event)
                                        }
                                    />
                                </form>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6} className="mt-4 mr-1">
                                <span>
                                    <ButtonComponent
                                        name={'Choose file'}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            this.fileUpload()
                                        }}
                                    />
                                    {'   '}
                                    {this.state.fileName}
                                </span>
                            </Col>
                            {this.state.formData.fileData && (
                                <Col md={1} className="mt-4 mr-1">
                                    <div
                                        className="clear-icon"
                                        onClick={(e) => {
                                            e.preventDefault()
                                            this.handleRemoveFile()
                                        }}
                                    >
                                        <span className="material-icons">
                                            clear
                                        </span>
                                    </div>
                                </Col>
                            )}
                            {/* for future reference */}
                            {/* <Col md={4} className="mt-4 ml-auto mr-1">
                                        <ButtonComponent
                                            name={ButtonName.UPLOAD_FILE}
                                            disabled={!this.state.formData.fileData}
                                            style={{
                                                width: '100%',
                                                fontSize: '14px',
                                            }}
                                            onClick={this.afterFileUpload}
                                        />
                                    </Col> */}
                        </Row>
                    </Card.Body>
                </Card>
            </Form>
        )
    }
}

export default CreateSupportTicket
