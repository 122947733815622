import React, { useState, useEffect, useRef } from 'react'
import {
    Col, Dropdown, DropdownButton, Form, NavDropdown, Overlay, OverlayTrigger,
    Pagination, Popover, Row
} from 'react-bootstrap'
import { connect } from 'react-redux';
import { callTemplateList, changeTemplateStatus, deleteTemplateList, setTemplateList } from '../../../actions/TemplatesAction';
import { setUILoaderState } from '../../../actions/UIAction';
import ButtonComponent from '../../../Components/Button/Button';
import SearchComponent from '../../../Components/Search/Search';
import TemplateList from '../../../Components/Templates/TemplateList';
import { AppConstant, Routes } from '../../../Constants';
import { Category } from '../../../Enum/CategoryEnum';
import { SubCategory } from '../../../Enum/SubCategoryEnum';
import { TemplateStatus } from '../../../Enum/TemplateStatusEnum';
import { WorkItemStatusEnum } from '../../../Enum/WorkItemStatus.enum';
import { Template } from '../../../Interfaces/TemplateInterface';

interface DropDownList {
    id: number,
    name: string
}

export interface changeStatusTemplateInterface {
    isActive: boolean,
    templateGuid: string,
    templateId: number,
    templateTypeId: number
}

function TemplateComponent(props) {
    const [searchValue, setSearchValue] = useState('');
    const [userList, setUserList] = useState([]);
    const [ show, setShowPopUp ] = useState( false );
    const [filter, filterParams] = useState({
        category: {
            id: 0,
            name: 'Category'
        },
        subCategory: {
            id: 0,
            name: 'Sub Category'
        },
        status: {
            id: 0,
            name: 'State'
        },
        searchKeyWord: searchValue
    });
    const [initialFilter, setInitialFilter] = useState({
        ...filter, category: { ...filter.category },
        subCategory: { ...filter.subCategory }
    });
    const [categoryList, setCategoryList] = useState<DropDownList[]>([]);
    const [subCategoryList, setSubCategoryList] = useState<DropDownList[]>([]);

    useEffect(() => {
        if (document.getElementById(AppConstant.SEARCH)) {
            (document.getElementById(AppConstant.SEARCH) as HTMLInputElement).value = '';
        }
        let categories: any[] = [];
        let subCategories: any[] = [];
        const categoryLength: number = Object.values(Category).length;
        const filterOnLoad = {
            ...filter, status: {
                id: TemplateStatus.New,
                name: TemplateStatus[TemplateStatus.New]
            }
        };
        let currentLength: number = 0;
        for (const key in Category) {
            if (currentLength < categoryLength && currentLength >= categoryLength / 2)
                categories.push({
                    id: +Category[key],
                    name: key
                });
            currentLength++;
        }
        const subCategoryLength: number = Object.values(SubCategory).length;
        currentLength = 0;
        for (const key in SubCategory) {
            if (currentLength < subCategoryLength && currentLength >= subCategoryLength / 2)
                subCategories.push({
                    id: +SubCategory[key],
                    name: key
                });
            currentLength++;
        }
        setCategoryList(categories);
        setSubCategoryList(subCategories);
        props.callTemplateList(filterOnLoad);
    }, []);

    useEffect(() => {
        const filterData = {
            ...filter,
            searchKeyWord: searchValue
        }
        filterParams(filterData)
    }, [searchValue])

    const submitFilterData = () => {
        props.callTemplateList(filter);
        setShowPopUp( false );
    }
    const setFilterData = (type: string, data: any) => {
        let filterValues: any = {};
        switch (type) {
            case 'category': {
                filterValues = { ...filter, category: { id: data.id, name: data.name } };
                break;
            }
            case 'sub_category': {
                filterValues = { ...filter, subCategory: { id: data.id, name: data.name } };
                break;
            }
            case 'status': {
                filterValues = { ...filter, status: { id: +TemplateStatus[data], name: data } };
                break;
            }
        }
        filterParams(filterValues);
    }
    const handleKeyPress = (event) => {
        if (event.keyCode === 13) {
            submitFilterData();
        }
    }
    const resetFormData = () => {
        props.callTemplateList();
        setShowPopUp( false );
    }
    const deleteTemplate = (id, guId) => {
        props.deleteTemplateList(id, guId, filter)
    }
    const changeTemplateStatusById = ({ id, guid, statusId }, isActive) => {
        const fixedTemplateId = 5;
        const changeTemplateValue: changeStatusTemplateInterface = Object.assign({}, {
            isActive: isActive,
            templateGuid: guid,
            templateId: id,
            templateTypeId: fixedTemplateId
        });
        props.changeTemplateStatus(changeTemplateValue, filter)
    }
    const editTemplate = (template) => {
        const depthValue = 3;
        const { id, guid, name } = template;
        props.history.push(`${Routes.ADD_NEW_WORK_Item}/true/${depthValue}/${id}/${guid}/null/null/true/${name}`)
    }

    const createItem = ( template, urlParam: any ) => {
        
        const { guid } = template;
        if( urlParam ) {
            props.history.push(`/addworkItem/false/3/0/null/0/null/false/null?guid=${guid}&showChildTasks=true`);
        } else {
            props.history.push(`/addworkItem/false/3/0/null/0/null/false/null?guid=${guid}`);
        }
    }

    const togglePopup = ( flag ) => {
        setShowPopUp( flag );
    }
    const category = [`Category`].map((data, index) => <Col key={index}
        className="mt-3"
        md={12}>
        <DropdownButton
            className="align-dropdown"
            key={"secondary"}
            id={`dropdown-variants-secondary`}
            variant={"secondary"}
            title={`${filter.category.name}`}
        >
            {categoryList.map((data, index) => {
                return <Dropdown.Item
                    key={index}
                    eventKey={`${index + 1}`}
                    onClick={() => setFilterData('category', data)}
                >
                    {data.name}
                </Dropdown.Item>
            })}
        </DropdownButton>
    </Col>)
    const subCategory = [filter.subCategory.name].map((data, index) => <Col key={index}
        className="mt-3"
        md={12}>
        <DropdownButton
            className="align-dropdown"
            key={"secondary"}
            id={`dropdown-variants-secondary`}
            variant={"secondary"}
            title={filter.subCategory.name}
        >
            {subCategoryList.map((data, index) => {
                return <Dropdown.Item
                    key={index}
                    eventKey={`${index + 1}`}
                    onClick={() => setFilterData('sub_category', data)}
                >
                    {data.name}
                </Dropdown.Item>
            })}
        </DropdownButton>
    </Col>)
    const Assigned = [`Status`].map((data, index) => <Col key={index}
        className="mt-3"
        md={12}>
        <DropdownButton
            className="align-dropdown"
            key={"secondary"}
            id={`dropdown-variants-secondary`}
            variant={"secondary"}
            title={filter.status.name}
        >
            {[TemplateStatus[TemplateStatus.Approved],
            TemplateStatus[TemplateStatus.Rejected],
            TemplateStatus[TemplateStatus.New]].map((data, index) => {
                return <Dropdown.Item
                    key={index}
                    eventKey={`${index + 1}`}
                    onClick={() => setFilterData('status', data)}
                >
                    {data}
                </Dropdown.Item>
            })}
        </DropdownButton>
    </Col>)
    return (
        <React.Fragment>
            <Row className="mt-2">
                <Col md={5}>
                    <SearchComponent
                        value={searchValue}
                        searchUserTable={setSearchValue}
                        keyPress={handleKeyPress}
                        style={{ 'float': 'inherit' }}
                        //   searchUserTable = {submitFilterDataOnEnter}
                        searchList={() => submitFilterData()}
                    />
                </Col>
                <Col md={{ span: 1, offset: 6 }}>
                    <OverlayTrigger
                        trigger="click"
                        key={'left'}
                        show={ show }
                        rootClose
                        placement={'left'}
                        overlay={
                            <Popover id={`popover-positioned-filter-${'left'}`}>
                                <Popover.Content id="popover-body-filter">
                                    <div>
                                        {category}
                                        {subCategory}
                                        {Assigned}
                                        <Row className="justify-content-end mt-3 mb-3">
                                            <Col md={6} sm={6} className="text-center">
                                                <ButtonComponent
                                                    size='sm'
                                                    name="Reset"
                                                    onClick={() => { filterParams(initialFilter); resetFormData() }}
                                                    style={{ width: 70 }}
                                                />
                                            </Col>
                                            <Col md={6} sm={6} className="text-center">
                                                <ButtonComponent
                                                    size='sm'
                                                    // disabled = {JSON.stringify(initialFilter) === JSON.stringify(filter)}
                                                    name="Submit"
                                                    onClick={() => submitFilterData()}
                                                    style={{ width: 70 }}
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                </Popover.Content>
                            </Popover>
                        }
                    >
                        <span onClick={ () => togglePopup( true ) } className="material-icons">
                            filter_alt
                  </span>
                    </OverlayTrigger>
                </Col>
            </Row>
            <Row className="mt-2">
                <TemplateList
                    tableList={props.Template.TemplateList}
                    deleteTemplate={deleteTemplate}
                    changeTemplateStatusById={changeTemplateStatusById}
                    editTemplate={editTemplate}
                    createItem={ createItem }
                />
            </Row>
        </React.Fragment>
    )
}

const mapStateToProps = (Template: any) => {
    return Template;
};

const mapDispatchToProps = (dispatch) => ({
    callTemplateList: (filterParams?: any) => dispatch(callTemplateList(filterParams)),
    changeTemplateStatus: (changeTemplateValue, filter) => dispatch(changeTemplateStatus(changeTemplateValue, filter)),
    setTemplateList: (template: Template[]) => dispatch(setTemplateList(template)),
    setUILoaderState: (isLoading: boolean) => dispatch(setUILoaderState(isLoading)),
    deleteTemplateList: (id: number, guId: string, filterParams) => dispatch(deleteTemplateList(id, guId, filterParams))
});

export default connect(mapStateToProps, mapDispatchToProps)(TemplateComponent);