import React from 'react'
import { OverlayTrigger, Popover, Table } from 'react-bootstrap';

export function Attachments(props) {
    return (
        <Table responsive hover size="sm">
  <tbody id = "tbody">
    {
      props.attachments.map((data, index) => ( <tr key = {index}>
          <td>
            <span>
               {data.name}
            </span>
          </td>
          <td>
            <span>{data.type}</span>
          </td>
          <td className = "text-right">
            <span>{data.updatedOn}</span>
          </td>
          <td className = "text-right">
            <span>{data.updatedBy}</span>
          </td>
          {
            props.isEdit === "true" ? <td className = "text-right">
            <OverlayTrigger
                  trigger="click"
                  key={'right'}
                  rootClose
                          placement={'right'}
                          overlay={
                              <Popover id={`popover-positioned-${'right'}`}>
                              <Popover.Content>
                                  <ul id = "popover-list">
                                      <li onClick = {() => {
                                        props.deleteAttachment(props.curRef, data.id, data.guid)
                                      }}>Delete</li>
                                      <li>
                                        <a href = {`${data.url}`} target="_blank">
                                        Download
                                        </a>
                                      </li>
                                  </ul>
                              </Popover.Content>
                              </Popover>
                          }
                      >
                          <span className="material-icons">
                          more_horiz
                          </span>
                      </OverlayTrigger>
            </td>
            :
            null
          }
          
        </tr>
      ))
    }
  </tbody>
</Table>
        )
}

export default Attachments;
