import { useEffect, useState } from 'react'
import React from 'react'
import { Dropdown, DropdownButton } from 'react-bootstrap'
import { getProjectOwners } from 'Services/ResourceService/ResourceService'
import { getAllocationResources } from 'Services/AllocationService/AllocationService'

function TableRows({
    rowsData,
    deleteTableRows,
    handleChange,
    projectResources,
}) {
    return rowsData.map((data, index) => {
        const {
            resourceMailId,
            month1AvailabilityHours,
            month2AvailabiltyHours,
            month1AllocatedHours,
            month2AllocatedHours,
            isEditable,
            isDeleted,
        } = data

        return !isDeleted ? (
            <tr key={index}>
                <td>
                    {isEditable ? (
                        <select
                            name="resourceMailId"
                            // value={resource}
                            onChange={(evnt) => handleChange(index, evnt)}
                        >
                            <option value="default">Select</option>
                            {projectResources.map((data, index) => (
                                <option key={index}>{data}</option>
                            ))}
                        </select>
                    ) : (
                        <input
                            type="text"
                            min="0"
                            max="200"
                            value={resourceMailId}
                            disabled
                            onChange={(evnt) => handleChange(index, evnt)}
                            name="resourceMailId"
                            className="form-control"
                        />
                    )}
                </td>
                <td>
                    <input
                        type="number"
                        min="0"
                        max="200"
                        value={month1AvailabilityHours}
                        disabled
                        onChange={(evnt) => handleChange(index, evnt)}
                        name="month1AvailabilityHours"
                        className="form-control"
                    />{' '}
                </td>
                <td>
                    <input
                        type="number"
                        min="0"
                        max="200"
                        disabled
                        value={month2AvailabiltyHours}
                        onChange={(evnt) => handleChange(index, evnt)}
                        name="month2AvailabiltyHours"
                        className="form-control"
                    />{' '}
                </td>
                <td>
                    <input
                        type="number"
                        value={month1AllocatedHours}
                        onChange={(evnt) => handleChange(index, evnt)}
                        name="month1AllocatedHours"
                        min="0"
                        max={month1AvailabilityHours}
                        className="form-control"
                    />{' '}
                </td>
                <td>
                    <input
                        type="number"
                        value={month2AllocatedHours}
                        onChange={(evnt) => handleChange(index, evnt)}
                        name="month2AllocatedHours"
                        min="0"
                        max={month2AvailabiltyHours}
                        className="form-control"
                    />{' '}
                </td>
                <td>
                    <button
                        className="btn btn-outline-danger"
                        onClick={() => deleteTableRows(index)}
                    >
                        x
                    </button>
                </td>
            </tr>
        ) : null
    })
}

export default TableRows
