import React from 'react';
import { Row, Col, Form, Card } from 'react-bootstrap';
import { Category } from '../../../../Enum/CategoryEnum';
import { SubCategory } from '../../../../Enum/SubCategoryEnum';


const TemplateCard = (props) => {
    return (
        <React.Fragment>
            <Card id = "text-editor-form1" className = "shadow p-3 mb-5 bg-white rounded">
                <Card.Body>
                    <Row>
                        <Col>
                        <Form.Label>Template</Form.Label>
                        <Form.Control className = "set-input-width" 
                            placeholder="Enter Name" 
                            value = {props.name}
                            required
                            onBlur = {() => props.handleOnBlur('name')}
                            onChange = {(event) => props.handleWorkItem('name', event)}
                            readOnly
                        />
                        </Col>
                    </Row>
                    <Row>
                        <Col md = {6}>
                        <Form.Label>Category</Form.Label>
                        <Form.Control className = "set-input-width" 
                            value = {Category[props.categoryId]}
                            readOnly
                        />
                        </Col>
                        <Col md = {6}>
                        <Form.Label>Sub Category</Form.Label>
                        <Form.Control className = "set-input-width" 
                            value = {SubCategory[props.subcategoryId]}
                            readOnly
                        />
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </React.Fragment>
    )
  };
  
  export default TemplateCard;