import React, { useState, useEffect } from 'react'
import {connect} from 'react-redux';
import {RouteComponentProps} from "react-router-dom";
import { useHistory } from 'react-router-dom';

import {Tabs, Tab, Container, Row, 
    Col, DropdownButton,
    Dropdown
} from 'react-bootstrap';
import './Tabset.css';

import ButtonComponent from '../Button/Button';
import TableComponent from '../Table/Table';
import SearchComponent from '../Search/Search';
import ToastMessage from '../../Utils/Toast';

import {setHeaderHeading, asyncActionModal} from '../../actions/UIAction';
import { callUserGroupListApi, 
    callApplicationListApi, 
    postApplicationList, 
    setAssignUnassignApplication, 
    searchUserGroupList, 
    searchAndFilterApplicationList} from '../../actions/ApplicationAndUserGroupAction';
import {setModalState,} from '../../actions/UIAction';

import { AppConstant } from '../../Constants';

import {Application} from '../../Interfaces/ApplicationListInterface';
import { AssignApplicationForm } from '../../Interfaces/AssignApplicationFormInterface';
import { ApplicationAndUserList } from '../../Interfaces/applicationAndUserGroupInterface';
import { AuthState } from '../../Interfaces/AuthConfiginterface';
import { UIState } from '../../Interfaces/UIStateInterface';

interface Props {
    ApplicationAndUserGroup: ApplicationAndUserList,
    AuthState: AuthState,
    UIState: UIState,
    asyncActionModal: (data) => void,
    callApplicationListApi: (groupId) => void,
    callUserGroupListApi: () => void,
    postApplicationList: (postData) => void,
    searchAndFilterApplicationList: (searchValue, filterValue) => void,
    searchUserGroupList: (searchValue) => void,
    setAssignUnassignApplication: (groupId) => void
    setHeaderHeading: (heading) => void,
    setModalState: (modalState) => void,
    staticContext: undefined
}

export function Tabset (props: RouteComponentProps<Props>) {
    const [toastState, setToastState] = useState<boolean>(false);
    const [key, setKey] = useState<string| null>(AppConstant.HOME);
    const [initialApplicationList, setInitialApplicationList] = useState<Application[]>([]);
    const [type, setType] = useState(AppConstant.GROUP);
    const [searchValue, setSearchValue] = useState('');
    const [filterValue, setfilterValue] = useState(AppConstant.ALL_APPS);
    const [currentUserGroupId,setCurrentUserGroup] = useState<number>(0);
    const [currentRoute,setCurrentRoute] = useState<number | null>(null);
    const [applicationListFormData, setApplicationListFormData] = useState<AssignApplicationForm>({
        groupId: 0,
        applications: []
    });
    const history = useHistory();
    useEffect(() => {
        const routeId = history.location.pathname.includes(AppConstant.APPLICATION_TYPE) ? +history.location.pathname.split('/')[2]
        : null;
        if(routeId === null) {
            setCurrentRoute(null);
        } else {
            setCurrentRoute(routeId);
        }
    }, []);

    useEffect(() => {
        (document.getElementById(AppConstant.SEARCH) as HTMLInputElement).value = '';
        const routeId = history.location.pathname.includes(AppConstant.APPLICATION_TYPE) ? +history.location.pathname.split('/')[2]
        : null;
        if(routeId === null) {
            setCurrentRoute(null);
            props.setHeaderHeading(AppConstant.USER_GROUP);
            props.callUserGroupListApi();
        } else {
            setCurrentRoute(routeId);
            props.setHeaderHeading(AppConstant.ASSIGN_APPLICATION);
            props.callApplicationListApi(routeId);
        }
    }, [history.location.key]);

    // Search user by name in table
    const searchUserTable = (searchData) => {
        props.searchUserGroupList(searchData);
    }

    // Search application by name in table
    const searchApplicationTable = (searchData) => {
        setSearchValue(searchData);
        props.searchAndFilterApplicationList(searchData, filterValue);
    }
    // Set table data if group or application
    const setTableData = (groupId: number) => {
        setSearchValue('');
        setCurrentUserGroup(groupId);
        history.push(`/application/${groupId}`);
    }

    const cancelApplicationListData = async() => {
        const {UIState: {modalState, modalResolve}} = props;
        const {applications} = applicationListFormData;
        if(JSON.stringify(props.ApplicationAndUserGroup.applicationList) === 
            JSON.stringify(props.ApplicationAndUserGroup.initialApplicationList)) {
            history.push('/');
        } else {
            props.asyncActionModal(() => history.push('/'));
            props.setModalState(true);
        }
    }

    // assign or un assign the application
    const setAssignApplication = (id: number) => {
        setInitialApplicationList(props.ApplicationAndUserGroup.applicationList)
        props.setAssignUnassignApplication(id);
    }

    // on submit button click post api to send assigned or unassigned application
    const submitApplicationList = () => {
        const postData: AssignApplicationForm = {
            groupId: currentUserGroupId,
            applications: props.ApplicationAndUserGroup.applicationList,
        }
        setApplicationListFormData(postData);
        props.postApplicationList(postData);
        setToastState(true);
        history.push('/')
    }

    // function to apply filter 
    const applicationFilter = (filterValue) => {
            setfilterValue(filterValue);
            props.searchAndFilterApplicationList(searchValue, filterValue);
    }

    const SaveAndCancelButton =  <Row className = "justify-content-end mt-3">
        <Col md = {1.3} sm = {2} className = "alignItems">
        <ButtonComponent
            size = 'sm' 
            name = "Cancel" 
            onClick = {() => cancelApplicationListData()}
        />
        </Col>
        <Col md = {1.3} sm = {2} className = "alignItems">
        <ButtonComponent
            size = 'sm' 
            name = "Submit" 
            disabled = {JSON.stringify(props.ApplicationAndUserGroup.applicationList) === JSON.stringify(initialApplicationList)}
            onClick = {() => submitApplicationList()}
        />
        </Col>
    </Row>

    const dropDown = [`${filterValue}`].map((data, index) =>  <Col key ={index} md = {2}>
        <DropdownButton
            className = "align-dropdown"
            key={"secondary"}
            id={`dropdown-variants-secondary`}
            variant={"secondary"}
            title={filterValue}
        >
        {[AppConstant.ASSIGN, AppConstant.UN_ASSIGN, AppConstant.ALL_APPS].map((data, index) => {
            return <Dropdown.Item
                key = {index}
                eventKey = {`${index + 1}`}
                onClick = {() => applicationFilter(data)}
            >
            {data}
            </Dropdown.Item>
        })}
        </DropdownButton>
        </Col>)
        return (
            <React.Fragment>
            <ToastMessage
                show = {toastState}
                setShow = {setToastState}
            />
            <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
          >
            <Tab eventKey="home" title="Home">
            <Container>
                <Row className = "mt-2">
                <Col md = {1} style = {{alignSelf: 'center'}}>
                    <div id = 'backIcon'>
                        {currentRoute !== null?
                        <span onClick = {() => cancelApplicationListData()} className="material-icons">
                            keyboard_backspace
                        </span>: null}
                    </div>
                </Col>
                <Col>
                <SearchComponent value = {searchValue} 
                    onChange = {setSearchValue}
                    searchUserTable = {currentRoute === null? searchUserTable: searchApplicationTable}
                />
                </Col>
                {currentRoute !== null? dropDown : null}
                </Row>
                <Row className ="mt-2">
                {currentRoute !== null?
                     <TableComponent 
                     tableList = {props.ApplicationAndUserGroup.applicationList} 
                     setTableData = {setTableData}
                     type = {type}
                     setAssignUnassignApplication = {setAssignApplication}
                     setCurrentUserGroup = {setCurrentUserGroup}
                     currentRoute = {currentRoute}
                 />
                 : 
                 <TableComponent 
                 tableList = {props.ApplicationAndUserGroup.userGroupList} 
                 setTableData = {setTableData}
                 type = {type}
                 setAssignUnassignApplication = {setAssignApplication}
                 setCurrentUserGroup = {setCurrentUserGroup}
                 currentRoute = {currentRoute}
             />
                }
                </Row>
            </Container>
            </Tab>
          </Tabs>
          {currentRoute !== null? SaveAndCancelButton: null}
          </React.Fragment>
        )
}

const mapStateToProps = (UIState, ApplicationAndUserGroup) => {
    return UIState;
};

const mapDispatchToProps = (dispatch) => ({
    setHeaderHeading: (heading: string) => dispatch(setHeaderHeading(heading)),
    callUserGroupListApi: () => dispatch(callUserGroupListApi()),
    callApplicationListApi: (groupId) => dispatch(callApplicationListApi(groupId)),
    setModalState: (modalState) => dispatch(setModalState(modalState)),
    asyncActionModal: (data) => dispatch(asyncActionModal(data)),
    postApplicationList: (postData) => dispatch(postApplicationList(postData)),
    setAssignUnassignApplication: (groupId) => dispatch(setAssignUnassignApplication(groupId)),
    searchUserGroupList: (searchValue) => dispatch(searchUserGroupList(searchValue)),
    searchAndFilterApplicationList: (searchValue, filterValue) => dispatch(searchAndFilterApplicationList(searchValue, filterValue))
  });

export default connect(mapStateToProps, mapDispatchToProps)(Tabset);
