import {ITEM} from '../actions/types';
import {AuthState} from '../Interfaces/AuthConfiginterface';
import { ApplicationAndUserList } from '../Interfaces/applicationAndUserGroupInterface';
import { applicationAndUserGroupList } from '../actions/types';
import { Application } from '../Interfaces/ApplicationListInterface';
import { UserGroup } from '../Interfaces/UserGroupInterface';

const initialState: ApplicationAndUserList= {
    applicationList: [],
    userGroupList: [],
    initialApplicationList: [],
    initialUserGroupList: [],
    dashBoardList: []
};

export const applicationAndUserGroupReducer = (state = initialState, action: any): ApplicationAndUserList => {
    if(action.type === applicationAndUserGroupList.SET_APPLICATION) {
        return {
            applicationList: [...action.data.data],
            userGroupList: [...state.userGroupList],
            initialApplicationList: [...action.data.data],
            initialUserGroupList: [...state.userGroupList],
            dashBoardList: [...state.dashBoardList]
        }
    } else if(action.type === applicationAndUserGroupList.SET_USER_GROUP) {
        return {
            applicationList: [...state.applicationList],
            userGroupList: [...action.data.data],
            initialApplicationList: [...state.applicationList],
            initialUserGroupList: [...action.data.data],
            dashBoardList: [...state.dashBoardList]
        }
    } else if(action.type === applicationAndUserGroupList.ASSIGN_APPLICATION) {
        const applicationList = (state.applicationList as Application[]).map((data: Application) => {
            if (data.applicationId === action.data.data) 
                return {...data, isAssigned: !data.isAssigned};
            else
                return data;
        });
        return {
            applicationList: [...applicationList],
            userGroupList: [...state.userGroupList],
            initialApplicationList: [...state.initialApplicationList],
            initialUserGroupList: [...state.initialUserGroupList],
            dashBoardList: [...state.dashBoardList]
        }
    } else if(action.type === applicationAndUserGroupList.SEARCH_USER_GROUP) {
        const {searchValue} = action.data;
        let userGroupList: any[];
        if(searchValue !== '') {
            userGroupList = (state.initialUserGroupList as UserGroup[]).filter((data: UserGroup) => {
                if (data.groupName.toLowerCase().includes(searchValue)) {
                    return data;
                }
            });
        } else {
            userGroupList = (state.initialUserGroupList as UserGroup[]);
        }
        return {
            applicationList: [...state.applicationList],
            userGroupList: [...userGroupList],
            initialApplicationList: [...state.initialApplicationList],
            initialUserGroupList: [...state.initialUserGroupList],
            dashBoardList: [...state.dashBoardList]
        }
    } else if(action.type === applicationAndUserGroupList.SEARCH_FILTER) {
        const {searchValue, filterValue} = action.data;
        let applicationList = (state.initialApplicationList as Application[]).filter((data: Application) => {
                if (filterValue === 'Assigned') 
                    return data.isAssigned === true;
                else if (filterValue === 'Unassigned')
                    return data.isAssigned === false;
                else 
                    return data
            });

            if(searchValue !== '') {
                applicationList = applicationList.filter((data) => {
                    if (data.applicationName.toLowerCase().includes(searchValue)) {
                        return data;
                    }
                });
            } else {
                applicationList = applicationList;
            }
        return {
            applicationList: [...applicationList],
            userGroupList: [...state.userGroupList],
            initialApplicationList: [...state.initialApplicationList],
            initialUserGroupList: [...state.initialUserGroupList],
            dashBoardList: [...state.dashBoardList]
        }
    } else if(action.type === applicationAndUserGroupList.SET_APPLICATION_DASHBOARD) {
        return {
            applicationList: [...state.applicationList],
            userGroupList: [...state.userGroupList],
            initialApplicationList: [...state.applicationList],
            initialUserGroupList: [...state.initialUserGroupList],
            dashBoardList: [...action.data.data]
        }
    } else {
        return state;
    }
};
