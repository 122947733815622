import React from 'react';
import { Table } from "react-bootstrap";
import { AppConstant } from '../../Constants';
import Item from './item';
import { authProvider } from '../../Services/Authentication/AuthProvider';

export function TemplateList(props) {
  const style = {
    width: '100px'
  }
  
  const roleName = localStorage.getItem(AppConstant.ADMIN_ROLE);
  const isAdmin = (authProvider.authenticationState === AppConstant.AUTHENTICATED) && (roleName === AppConstant.ADMIN);

  return (
    <Table responsive hover size="sm">
      <thead>
        <tr id="tableHeading">
          <th>Name</th>
          <th>Category</th>
          <th >Sub Category</th>
          <th >Created By</th>
          <th >State</th>
        </tr>
      </thead>
      <tbody id="tbody">
        {
          props.tableList.map((data, index) => (<Item
            isAdmin={ isAdmin }
            data={data}
            index={ index }
            deleteTemplate = { props.deleteTemplate }
            editTemplate = { props.editTemplate }
            createItem = { props.createItem }
            changeTemplateStatusById = { props.changeTemplateStatusById }
            /> ))
        }
      </tbody>
    </Table>
  )
}

export default TemplateList;
