import React from 'react'
import { Tabs, Tab, Row, Col } from 'react-bootstrap'
import ProjectViewReports from './ProjectViewReports'
import UserViewReports from './UserViewReports'

function ReportsContainer() {
    return (
        <React.Fragment>
            <Tabs
                defaultActiveKey="listOfResources"
                id="uncontrolled-tab-example"
            >
                <Tab
                    eventKey="listOfResources"
                    title="Project Report"
                    statusSet={new Set([])}
                >
                    <div id="firstTab">
                        <ProjectViewReports />
                    </div>
                </Tab>

                {/* localStorage.getItem('adminRole') != 'Admin' ? null : ( */}
                {/* <Tab eventKey="assignedTickets" title="Allocation Primary">
                    <div id="secondTab">
                        <AllocationPrimary />
                    </div>
                </Tab> */}

                <Tab eventKey="assignedTickets2" title="User Report">
                    <div id="secondTab">
                        <UserViewReports />
                    </div>
                </Tab>
            </Tabs>
        </React.Fragment>
    )
}

export default ReportsContainer
