import {WorkItemList} from '../actions/types';
import { WorkItemReducer } from '../Interfaces/WorkItemInterface';

const initialState: WorkItemReducer = {
    WorkItemList: [],
    pagination: {
        currentPage: 1, 
        hasNext: false, 
        hasPrevious: false, 
        pageSize: 10, 
        totalCount: 0, 
        totalPages: 1
    }
};

export const workItemReducer = (state = initialState, action: any): WorkItemReducer => {
    if(action.type === WorkItemList.SET_WORKITEM) {
        const {WorkItem} = action.data;
        return {
            ...state,
            WorkItemList: [...WorkItem]
        }
    } else if(action.type === WorkItemList.WORKITEM_PAGINATION) {
        return {
            ...state,
            pagination: {...action.data}
        }
    } else {
        return state;
    }
};
