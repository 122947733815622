import React, { useEffect, useState, useRef } from 'react'
import axios from 'axios'
import { Col, Row, Card, Table, Popover, Overlay } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import moment from 'moment'
import './SupportTicket.css'
import { API_URL, deptNameWithId } from '../../Services/Constants'
import { Routes } from '../../Constants'
import { SupportTicketStatusEnum } from '../../Enum/SupportTicketEnum'
import { SupportTicket } from '../../Interfaces/SupportTicketInterface'
import { useHistory } from 'react-router'
import Pagination from 'Utils/Pagination'

const PageSize = 20

let deptNameWithIdCopy = [
    {
        supportTicketDepartmentId: 0,
        departmentName: 'All',
        categories: null,
        priorities: null,
    },
    ...deptNameWithId,
]

const allOption = {
    departmentId: 0,
    departmentName: '',
    id: 0,
    userId: 0,
    userName: 'All',
}

let supportTicketDepartmentDataCopy: Object[] = [allOption]
// supportTicketDepartmentDataCopy.push(allOption)

const SupportTicketComponent = (props: any) => {
    const history = useHistory()
    const [supportTickets, setSupportTickets] = useState<Array<SupportTicket>>(
        []
    )
    const [filterTickets, setFilterTickets] = useState<Array<SupportTicket>>([])
    const [searchTerm, setSearchTerm] = useState('')
    const [isSearch, setIsSearch] = useState(false)
    const [show, setShow] = useState(false)
    const [page, setPage] = useState(1)
    const [totalData, setTotalData] = useState(0)
    const [status, setStatus] = useState(0)
    const [department, setDepartment] = useState(0)
    const [assignee, setAssignee] = useState(0)
    const [
        supportTicketDepartmentData,
        setSupportTicketDepartmentData,
    ] = useState([])
    const [target, setTarget] = useState(null)
    const ref = useRef(null)
    let myData: any
    let supportTicketData: any
    const guid = localStorage.getItem('guid') as any
    const MAX_LENGTH = 50
    const supportTicketStatus = [
        {
            displayName: 'All',
            statusValue: 0,
        },
        {
            displayName: 'Open',
            statusValue: 1,
        },
        {
            displayName: 'In Progress',
            statusValue: 2,
        },
        {
            displayName: 'Closed',
            statusValue: 3,
        },
    ]

    const tableHeading = [
        {
            id: 1,
            name: 'Subject',
        },
        {
            id: 3,
            name: 'From',
        },
        {
            id: 4,
            name: 'Status',
        },
        {
            id: 5,
            name: 'Sent',
        },
    ]

    const getSupportTicketList = async () => {
        console.log('Assigned Guid', props.AssigneeGuid)
        console.log('SupportTicketDepartmentMemberGuid ', props.AssigneeGuid)
        console.log('UserGuid', props.UserGuid)
        const baseUrl = `${API_URL}/supports`
        try {
            const response = await axios.get(baseUrl, {
                params: {
                    UserGuid: guid,
                    Page: page,
                    PageSize,
                    SupportTicketStatusId: status,
                    SearchKeyword: searchTerm.length ? searchTerm : null,
                },
            })
            myData = response.data
            supportTicketData = myData.data
            if (response.status >= 400) {
                console.log(supportTicketData.errors)
            }
            setTotalData(myData.totalCount)
            setSupportTickets(supportTicketData)
            setFilterTickets(supportTicketData)
            console.log(supportTicketData)
        } catch (e) {
            console.log(e)
        }
    }

    const getSupportTicketListForAssignedTickets = async () => {
        const baseUrl = `${API_URL}/supports`
        try {
            const response = await axios.get(baseUrl, {
                params: {
                    AssigneeGuid: props.AssigneeGuid,
                    Page: page,
                    PageSize,
                    SupportTicketStatusId: status,
                    SearchKeyword: searchTerm.length ? searchTerm : null,
                    SupportTicketAssigneeId: assignee,
                },
            })
            myData = response.data
            supportTicketData = myData.data
            if (response.status >= 400) {
                console.log(supportTicketData.errors)
            }
            setTotalData(myData.totalCount)
            setSupportTickets(supportTicketData)
            setFilterTickets(supportTicketData)
        } catch (e) {
            console.log(e)
        }
    }

    const getSupportTicketListForDepartment = async () => {
        const baseUrl = `${API_URL}/supports`
        try {
            const response = await axios.get(baseUrl, {
                params: {
                    SupportTicketDepartmentMemberGuid:
                        props.SupportTicketDepartmentMemberGuid,
                    Page: page,
                    PageSize,
                    SupportTicketStatusId: status,
                    SearchKeyword: searchTerm.length ? searchTerm : null,
                    SupportTicketDepartmentId: department,
                },
            })
            myData = response.data
            supportTicketData = myData.data
            if (response.status >= 400) {
                console.log(supportTicketData.errors)
            }
            setTotalData(myData.totalCount)
            setSupportTickets(supportTicketData)
            setFilterTickets(supportTicketData)
            console.log(supportTicketData)
        } catch (e) {
            console.log(e)
        }
    }

    const handleStatusChange = (event) => {
        setStatus(event.target.value)
        setPage(1)
    }

    const handleChangeAssignee = (event) => {
        setAssignee(event.target.value)
        setPage(1)
    }

    const handleChangeDepartment = (event) => {
        setDepartment(event.target.value)
        setPage(1)
    }

    const handleChangeSearch = (event) => {
        // call handleOnSearch when we clear the search box text
        if (!event.target.value && searchTerm.length) {
            handleOnSearch(false)
        }
        setSearchTerm(event.target.value)
    }

    const handleClick = (event) => {
        setShow(!show)
        setTarget(event.target)
    }

    const onClickDelete = (id, guid) => {
        const ticketId = parseInt(id)
        const supportGroupMemberGuid = localStorage.getItem(
            'supportGroupMemberGuid'
        )
        const baseUrl = `${API_URL}/supports/${ticketId}/${guid}/${supportGroupMemberGuid}`
        axios.delete(baseUrl).then((resp) => {
            getSupportTicketList()
        })
    }

    const onClickRow = (id, guid) => {
        history.push(
            `${Routes.EDIT_SUPPORT_TICKET}/${props.currentTab}/${id}/${guid}`
        )
    }

    const handleDataCalls = () => {
        if (props.AssigneeGuid !== '' && +props.currentTab === 2) {
            getSupportTicketListForAssignedTickets()
        } else if (
            props.SupportTicketDepartmentMemberGuid !== '' &&
            +props.currentTab === 3
        ) {
            getSupportTicketListForDepartment()
        } else if (props.UserGuid !== '' && props.currentTab === 1) {
            getSupportTicketList()
        }
    }

    const handleOnSearch = (value) => {
        if (page === 1) {
            setIsSearch(!isSearch)
        } else {
            setPage(1)
        }
    }

    async function getAssigneeList() {
        const baseUrl = `${API_URL}/support-group`

        axios
            .get(baseUrl, {
                params: {
                    AssigneeGuid: props.AssigneeGuid,
                    Page: page,
                    PageSize,
                    SupportTicketStatusId: status,
                    SearchKeyword: searchTerm.length ? searchTerm : null,
                    SupportTicketAssigneeId: assignee,
                },
            })
            .then((response) => {
                setSupportTicketDepartmentData(response.data)
                supportTicketDepartmentDataCopy = [allOption].concat(
                    response.data
                )
            })
            .catch((error) => console.log(error))
    }

    useEffect(() => {
        if (props.assignee) getAssigneeList()
    }, [])

    useEffect(() => {
        handleDataCalls()
    }, [page, status, isSearch, department, assignee])

    return (
        <Card className="support-ticket-card">
            <Card.Body>
                <Row className="mt-1">
                    <Col md={5} className="top-search-container pl-3">
                        <input
                            id="search-support-ticket-input"
                            placeholder="Search Support Ticket..."
                            style={{ float: 'left' }}
                            onChange={handleChangeSearch}
                            onKeyPress={(event) => {
                                event.key === 'Enter' && handleOnSearch(true)
                            }}
                        />
                        <div
                            className="searchIcon"
                            onClick={() => {
                                handleOnSearch(true)
                            }}
                        >
                            <span className="material-icons">search</span>
                        </div>
                    </Col>
                    <Col md={4} className="top-input-container ml-auto mr-3">
                        <Row className="position-container">
                            <Col lg={6} className="top-dropdown-container">
                                <select
                                    onChange={handleStatusChange}
                                    defaultValue={'DEFAULT'}
                                >
                                    <option disabled value="DEFAULT">
                                        Status
                                    </option>
                                    {supportTicketStatus.map((item: any, i) => (
                                        <option
                                            key={i}
                                            value={item.statusValue}
                                        >
                                            {item.displayName}
                                        </option>
                                    ))}
                                </select>
                            </Col>
                            {props.assignee && (
                                <Col lg={6} className="top-dropdown-container">
                                    <select
                                        onChange={handleChangeAssignee}
                                        defaultValue={'DEFAULT1'}
                                    >
                                        <option disabled value="DEFAULT1">
                                            Assignee
                                        </option>
                                        {supportTicketDepartmentDataCopy &&
                                            supportTicketDepartmentDataCopy.map(
                                                (item: any, i) => (
                                                    <option
                                                        key={i}
                                                        value={item.id}
                                                    >
                                                        {item.userName}
                                                    </option>
                                                )
                                            )}
                                    </select>
                                </Col>
                            )}
                            {props.department && (
                                <Col lg={6} className="top-dropdown-container">
                                    <select
                                        onChange={handleChangeDepartment}
                                        defaultValue={'DEFAULT2'}
                                    >
                                        <option disabled value="DEFAULT2">
                                            Department
                                        </option>
                                        {deptNameWithIdCopy.map(
                                            (item: any, i) => (
                                                <option
                                                    key={i}
                                                    value={
                                                        item.supportTicketDepartmentId
                                                    }
                                                >
                                                    {item.departmentName}
                                                </option>
                                            )
                                        )}
                                    </select>
                                </Col>
                            )}
                        </Row>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col md={12}>
                        <Table
                            responsive="md"
                            className="support-ticket-table-container"
                        >
                            <thead>
                                <tr id="tableHeading">
                                    {tableHeading.map((item) => (
                                        <th key={item.name}>{item.name}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    // TODO remove it after completing the search and filter api calls
                                    // filterTickets.filter((filter: SupportTicket) => {
                                    //     if (searchTerm === '') {
                                    //         return filter
                                    //     } else if (filter.subject.toLowerCase().includes(searchTerm.toLowerCase())) {
                                    //         return filter
                                    //     }
                                    //     else if (filter.sender.toLowerCase().includes(searchTerm.toLowerCase())) {
                                    //         return filter
                                    //     }
                                    // }).map((item: SupportTicket) => (
                                    filterTickets.map((item: SupportTicket) => (
                                        <tr key={item.id}>
                                            {
                                                <td
                                                    className="sub-tickets-td"
                                                    onClick={() =>
                                                        onClickRow(
                                                            item.id,
                                                            item.guid
                                                        )
                                                    }
                                                >
                                                    <div className="sub-tickets-column pt-2">
                                                        <div className="left">
                                                            {item.subject
                                                                .length >
                                                            MAX_LENGTH ? (
                                                                <Link
                                                                    style={{
                                                                        float:
                                                                            'left',
                                                                    }}
                                                                    className="subject-link"
                                                                    to={`${Routes.EDIT_SUPPORT_TICKET}/${props.currentTab}/${item.id}/${item.guid}`}
                                                                >
                                                                    {`${item.subject.substring(
                                                                        0,
                                                                        MAX_LENGTH
                                                                    )}...`}
                                                                </Link>
                                                            ) : (
                                                                <Link
                                                                    style={{
                                                                        float:
                                                                            'left',
                                                                    }}
                                                                    className="subject-link"
                                                                    to={`${Routes.EDIT_SUPPORT_TICKET}/${props.currentTab}/${item.id}/${item.guid}`}
                                                                >
                                                                    {
                                                                        item.subject
                                                                    }
                                                                </Link>
                                                            )}
                                                        </div>
                                                    </div>
                                                </td>
                                            }
                                            {
                                                <td
                                                    className="from-column-td"
                                                    onClick={() =>
                                                        onClickRow(
                                                            item.id,
                                                            item.guid
                                                        )
                                                    }
                                                >
                                                    <div className="from-column">
                                                        <div
                                                            className="left"
                                                            style={
                                                                item.sender
                                                                    .slice(0, 1)
                                                                    .toLowerCase() ===
                                                                'a'
                                                                    ? {
                                                                          background:
                                                                              'aquamarine',
                                                                      }
                                                                    : item.sender
                                                                          .slice(
                                                                              0,
                                                                              1
                                                                          )
                                                                          .toLowerCase() ===
                                                                      'f'
                                                                    ? {
                                                                          background:
                                                                              'blueviolet',
                                                                      }
                                                                    : item.sender
                                                                          .slice(
                                                                              0,
                                                                              1
                                                                          )
                                                                          .toLowerCase() ===
                                                                      'k'
                                                                    ? {
                                                                          background:
                                                                              'crimson',
                                                                      }
                                                                    : item.sender
                                                                          .slice(
                                                                              0,
                                                                              1
                                                                          )
                                                                          .toLowerCase() ===
                                                                      'p'
                                                                    ? {
                                                                          background:
                                                                              'lilac',
                                                                      }
                                                                    : item.sender
                                                                          .slice(
                                                                              0,
                                                                              1
                                                                          )
                                                                          .toLowerCase() ===
                                                                      'v'
                                                                    ? {
                                                                          background:
                                                                              'yelloworange',
                                                                      }
                                                                    : {
                                                                          background:
                                                                              'crimson',
                                                                      }
                                                            }
                                                        >
                                                            {item.sender.slice(
                                                                0,
                                                                1
                                                            )}
                                                        </div>
                                                        <div className="right pt-1">
                                                            {item.sender}
                                                        </div>
                                                    </div>
                                                </td>
                                            }
                                            {
                                                <td
                                                    className="state-td"
                                                    onClick={() =>
                                                        onClickRow(
                                                            item.id,
                                                            item.guid
                                                        )
                                                    }
                                                >
                                                    <div className="state-column pt-1">
                                                        <div className="left pt-2">
                                                            <div
                                                                className="left-inner-div"
                                                                style={
                                                                    item.supportTicketStatusId ===
                                                                    SupportTicketStatusEnum.Open
                                                                        ? {
                                                                              background:
                                                                                  'rgb(50, 205, 140)',
                                                                          }
                                                                        : item.supportTicketStatusId ===
                                                                          SupportTicketStatusEnum.In_Progress
                                                                        ? {
                                                                              background:
                                                                                  'rgb(163,88,232',
                                                                          }
                                                                        : item.supportTicketStatusId ===
                                                                          SupportTicketStatusEnum.Closed
                                                                        ? {
                                                                              background:
                                                                                  'rgb(207,20,43)',
                                                                          }
                                                                        : {
                                                                              background:
                                                                                  'gray',
                                                                          }
                                                                }
                                                            ></div>
                                                        </div>
                                                        <div className="right">
                                                            {item.supportTicketStatusId ===
                                                            SupportTicketStatusEnum.Open ? (
                                                                <span>
                                                                    Open
                                                                </span>
                                                            ) : item.supportTicketStatusId ===
                                                              SupportTicketStatusEnum.In_Progress ? (
                                                                <span>
                                                                    In Progress
                                                                </span>
                                                            ) : item.supportTicketStatusId ===
                                                              SupportTicketStatusEnum.Closed ? (
                                                                <span>
                                                                    Closed
                                                                </span>
                                                            ) : (
                                                                <span>
                                                                    ----
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </td>
                                            }
                                            {
                                                <td
                                                    className="send-date-td"
                                                    onClick={() =>
                                                        onClickRow(
                                                            item.id,
                                                            item.guid
                                                        )
                                                    }
                                                >
                                                    <div className="send-date-column pt-2">
                                                        {moment(item.receivedAt)
                                                            .add(5, 'hours')
                                                            .add(30, 'minutes')
                                                            .format(
                                                                'MMM DD, YYYY hh:mm a'
                                                            )}
                                                    </div>
                                                </td>
                                            }
                                            {localStorage.getItem(
                                                'supportGroupMemberGuid'
                                            ) == '' ||
                                            props.currentTab == 1 ? null : (
                                                <td className="action-td">
                                                    <div className="action-container pt-2">
                                                        <span
                                                            className="material-icons"
                                                            onClick={
                                                                handleClick
                                                            }
                                                        >
                                                            more_horiz
                                                        </span>
                                                    </div>
                                                    <Overlay
                                                        show={show}
                                                        target={target}
                                                        placement="bottom"
                                                        container={ref.current}
                                                        containerPadding={20}
                                                        rootClose
                                                        onHide={() =>
                                                            setShow(false)
                                                        }
                                                    >
                                                        <Popover id="popover-contained">
                                                            <Popover.Content>
                                                                <ul id="popover-list">
                                                                    <li>
                                                                        <Link
                                                                            style={{
                                                                                float:
                                                                                    'left',
                                                                            }}
                                                                            className="subject-link"
                                                                            to={`${Routes.EDIT_SUPPORT_TICKET}/${props.currentTab}/${item.id}/${item.guid}`}
                                                                        >
                                                                            Edit
                                                                            Support
                                                                            Ticket
                                                                        </Link>
                                                                    </li>
                                                                    <li
                                                                        onClick={() =>
                                                                            onClickDelete(
                                                                                item.id,
                                                                                item.guid
                                                                            )
                                                                        }
                                                                    >
                                                                        Delete
                                                                    </li>
                                                                </ul>
                                                            </Popover.Content>
                                                        </Popover>
                                                    </Overlay>
                                                </td>
                                            )}
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Pagination
                            totalRecords={totalData}
                            limit={PageSize}
                            currentPage={page}
                            handleValueChange={(value: number) => {
                                setPage(value)
                            }}
                        />
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

export default SupportTicketComponent
