import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Input from '@material-ui/core/Input'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
// Icons
import EditIcon from '@material-ui/icons/EditOutlined'
import DoneIcon from '@material-ui/icons/DoneAllTwoTone'
import RevertIcon from '@material-ui/icons/NotInterestedOutlined'
import {
    Col,
    Dropdown,
    DropdownButton,
    Form,
    Nav,
    OverlayTrigger,
    Popover,
    Row,
} from 'react-bootstrap'
import {
    createProjection,
    getProjection,
    getProjectOwners,
    getProjects,
    getProjectsFromKickOffBilling,
    getReportingManagers,
    getUsersList,
    updateProjection,
} from 'Services/ResourceService/ResourceService'
import { useHistory } from 'react-router-dom'
import 'bootstrap/js/src/collapse.js'
import 'bootstrap/dist/css/bootstrap.min.css'
import moment from 'moment'
import './Projection.css'
import DatePicker from 'react-datepicker'
import { useEffect, useState } from 'react'

import ButtonComponent from 'Components/Button/Button'
import { ButtonName } from 'Constants'
import { TextField } from '@material-ui/core'
import { Typeahead } from 'react-bootstrap-typeahead'

interface State {
    projectionDropDownItems?: any
}
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
    },
    table: {
        minWidth: 250,
    },
    selectTableCell: {
        width: 60,
    },
    tableCell: {
        width: 30,
        height: 40,
    },
    input: {
        width: 40,
        height: 40,
    },
}))
//Custom cell
const CustomTableCell = ({ row, name, onChange, rows, setRows }) => {
    const classes = useStyles()
    const { isEditMode } = row
    return (
        <TableCell align="left" className={classes.tableCell}>
            {isEditMode && name != 'week' ? (
                <Input
                    type="number"
                    inputProps={{ maxLength: 3 }}
                    value={row[name]}
                    name={name}
                    onChange={(e) => onChange(e, row, rows, setRows)}
                    className={classes.input}
                />
            ) : (
                row[name]
            )}
        </TableCell>
    )
}

const CustomTableCell2 = ({ row, name, onChange, rows2, setRows2 }) => {
    const classes = useStyles()
    const { isEditMode } = row

    return (
        <TableCell align="left" className={classes.tableCell}>
            {isEditMode && name != 'week' ? (
                <Input
                    type="number"
                    inputProps={{ maxLength: 3 }}
                    value={row[name]}
                    name={name}
                    onChange={(e) => onChange(e, row, rows2, setRows2)}
                    className={classes.input}
                />
            ) : (
                row[name]
            )}
        </TableCell>
    )
}

export default function ProjectionPo(state: State) {
    state = {
        projectionDropDownItems: {
            projectId: {
                id: 0,
                name: 'Project',
            },
            projectOwnerId: {
                id: 0,
                name: 'PO',
            },
            year: {
                id: 0,
                name: 'Year',
            },
            month: {
                id: 0,
                name: 'Month',
            },
        },
    }

    interface ProjectOwnerType {
        firstName: string
        lastName: string
        employeeID: string
    }

    let monthPairs = [
        { id: '1', name: 'Jan - Feb', firstMonth: 'Jan', secondmonth: 'Feb' },
        { id: '2', name: 'Feb - Mar', firstMonth: 'Feb', secondmonth: 'Mar' },
        { id: '3', name: 'Mar - Apr', firstMonth: 'Mar', secondmonth: 'Apr' },
        { id: '4', name: 'Apr - May', firstMonth: 'Apr', secondmonth: 'May' },
        { id: '5', name: 'May - Jun', firstMonth: 'May', secondmonth: 'Jun' },
        { id: '6', name: 'Jun - Jul', firstMonth: 'Jun', secondmonth: 'Jul' },
        { id: '7', name: 'Jul - Aug', firstMonth: 'Jul', secondmonth: 'Aug' },
        { id: '8', name: 'Aug - Sep', firstMonth: 'Aug', secondmonth: 'Sep' },
        { id: '9', name: 'Sep - Oct', firstMonth: 'Sep', secondmonth: 'Oct' },
        { id: '10', name: 'Oct - Nov', firstMonth: 'Oct', secondmonth: 'Nov' },
        { id: '11', name: 'Nov - Dec', firstMonth: 'Nov', secondmonth: 'Dec' },
        { id: '12', name: 'Dec - Jan', firstMonth: 'Dec', secondmonth: 'Jan' },
    ]
    const [monthSingle, setMonthSingle]: any = useState({
        firstMonth: 'Month 1',
        secondmonth: 'Month 2',
    })
    const [years, setYears]: any = useState([])
    const [startDate, setStartDate] = useState(new Date())
    const [projects, setProjects] = useState([])
    const [projectOwners, setProjectOwners]: any = useState([])
    const [projectionData, setProjectionData]: any = useState([])
    const [projectionState, setProjectionState] = useState(state)
    const [isLocked, setIsLocked]: any = useState(false)
    const [isMonth1Locked, setIsMonth1Locked]: any = useState(false)
    const [isMonth2Locked, setIsMonth2Locked]: any = useState(false)
    const [rows, setRows]: any = useState([])
    const [rows2, setRows2]: any = useState([])
    const [showMsg, setShowMsg] = useState(true)
    const [message, setMessage] = useState('')
    const [updateStatus, setUpdateStatus] = useState(false)
    const [projectSelected, setProjectSelected]: any = useState()
    const [projectOwnerSelected, setProjectOwnerSelected]: any = useState()

    useEffect(() => {
        if (projectSelected && projectSelected.length)
            setDropDownValue('projectId', projectSelected[0])
    }, [projectSelected])

    useEffect(() => {
        if (projectOwnerSelected && projectOwnerSelected.length)
            setDropDownValue('projectOwnerId', projectOwnerSelected[0])
    }, [projectOwnerSelected])
    useEffect(() => {
        let currentDate = new Date().getDate()
        let currentMonth = new Date().getMonth() + 1
        if (currentDate < 24) {
            let monthResult = monthPairs.find((months) => {
                return months.id == currentMonth + ''
            })

            const dropDownValue = {
                ...projectionState,
                projectionDropDownItems: {
                    ...projectionState.projectionDropDownItems,
                    month: {
                        id: monthResult?.id,
                        name: monthResult?.name,
                    },
                    year: {
                        id: new Date().getFullYear(),
                        name: new Date().getFullYear(),
                    },
                },
            }
            setProjectionState(dropDownValue)
            setMonthSingle({
                ...monthSingle,
                firstMonth: monthResult?.firstMonth,
                secondmonth: monthResult?.secondmonth,
            })
        } else {
            let monthResult = monthPairs.find((months) => {
                return months.id == currentMonth + 1 + ''
            })

            const dropDownValue = {
                ...projectionState,
                projectionDropDownItems: {
                    ...projectionState.projectionDropDownItems,
                    month: {
                        id: monthResult?.id,
                        name: monthResult?.name,
                    },
                    year: {
                        id: new Date().getFullYear(),
                        name: new Date().getFullYear(),
                    },
                },
            }
            setProjectionState(dropDownValue)
            setMonthSingle({
                ...monthSingle,
                firstMonth: monthResult?.firstMonth,
                secondmonth: monthResult?.secondmonth,
            })
        }
        let options: any = []
        let prevYear = 2021
        for (let i = 0; i <= 40; i++) {
            options.push((prevYear = prevYear + 1))
        }
        setYears([...years, ...options])
        getProjectsFromKickOffBilling().then((response: any) => {
            setProjects(response.data)
        })

        getProjectOwners()
            .then((response: any) => {
                setProjectOwners(response.data)
            })
            .catch((error) => console.log(error, ' error'))
    }, [])

    useEffect(() => {
        try {
            getProjection(projectionState.projectionDropDownItems)
                .then((response: any) => {
                    setProjectionData(response.data)

                    setIsLocked(response.data.isLocked)
                    if (response.data.isLocked) {
                        setRows([
                            ...response.data.projectionDetailMonth1.map(
                                (items, index) => {
                                    return {
                                        ...items,
                                        id: 20 + index,
                                        isEditMode: false,
                                    }
                                }
                            ),
                        ])
                        setRows2([
                            ...response.data.projectionDetailMonth2.map(
                                (items, index) => {
                                    return {
                                        ...items,
                                        id: index,
                                        isEditMode: false,
                                    }
                                }
                            ),
                        ])
                    } else {
                        setRows([
                            ...response.data.projectionDetailMonth1.map(
                                (items, index) => {
                                    return {
                                        ...items,
                                        id: 20 + index,
                                        isEditMode: true,
                                    }
                                }
                            ),
                        ])
                        setRows2([
                            ...response.data.projectionDetailMonth2.map(
                                (items, index) => {
                                    return {
                                        ...items,
                                        id: index,
                                        isEditMode: true,
                                    }
                                }
                            ),
                        ])
                    }
                })
                .catch((error) => console.log('some error'))
        } catch (error) {}
    }, [
        projectionState.projectionDropDownItems.projectId.id,
        projectionState.projectionDropDownItems.projectOwnerId.id,
        projectionState.projectionDropDownItems.year.id,
        projectionState.projectionDropDownItems.month.id,
        updateStatus,
    ])
    const setButtonStatus = () => {
        if (
            projectionState.projectionDropDownItems.projectId.id == 0 ||
            projectionState.projectionDropDownItems.projectOwnerId.id == 0 ||
            projectionState.projectionDropDownItems.year.id == 0 ||
            projectionState.projectionDropDownItems.month.id == 0 ||
            isLocked
        ) {
            return true
        }
    }
    const postProjectionData = () => {
        let projectionDetailMonth1: any = []
        let projectionDetailMonth2: any = []

        if (projectionData.projectionId) {
            rows.map((items, index) => {
                projectionDetailMonth1.push({
                    projectionLocationYVRHours: parseInt(
                        items.projectionLocationYVRHours
                    ),
                    projectionLocationNoidaHours: parseInt(
                        items.projectionLocationNoidaHours
                    ),
                    week: items.week,
                    projectionDetailId: parseInt(items.projectionDetailId),
                })
            })
            rows2.map((items, index) => {
                projectionDetailMonth2.push({
                    projectionLocationYVRHours: parseInt(
                        items.projectionLocationYVRHours
                    ),
                    projectionLocationNoidaHours: parseInt(
                        items.projectionLocationNoidaHours
                    ),
                    week: items.week,
                    projectionDetailId: parseInt(items.projectionDetailId),
                })
            })
        } else {
            rows.map((items, index) => {
                projectionDetailMonth1.push({
                    projectionLocationYVRHours: parseInt(
                        items.projectionLocationYVRHours
                    ),
                    projectionLocationNoidaHours: parseInt(
                        items.projectionLocationNoidaHours
                    ),
                    week: items.week,
                })
            })
            rows2.map((items, index) => {
                projectionDetailMonth2.push({
                    projectionLocationYVRHours: parseInt(
                        items.projectionLocationYVRHours
                    ),
                    projectionLocationNoidaHours: parseInt(
                        items.projectionLocationNoidaHours
                    ),
                    week: items.week,
                })
            })
        }
        const { projectionDropDownItems } = projectionState

        let formObj = {
            projectionId: parseInt(projectionData.projectionId),
            projectId: parseInt(projectionDropDownItems.projectId.id),
            projectOwnerId: projectionDropDownItems.projectOwnerId.id,
            month: parseInt(projectionDropDownItems.month.id),
            year: projectionDropDownItems.year.id,
            projectionDetailMonth1: projectionDetailMonth1,
            projectionDetailMonth2: projectionDetailMonth2,
        }
        projectionData.projectionId
            ? updateProjection(formObj).then((res) => {
                  if (res?.status != 200) {
                      setMessage(
                          `There's some error while upating data, please try after refreshing the page`
                      )
                  } else {
                      setMessage('Projection has been updated successfully')
                  }
              })
            : createProjection(formObj).then((response: any) => {
                  setMessage('Projection has been added successfully')
              })

        setTimeout(() => {
            setShowMsg(false)
            setUpdateStatus(!updateStatus)
        }, 5000)
        setMessage('')
        setShowMsg(true)
    }
    const [previous, setPrevious] = React.useState({})
    const classes = useStyles()

    const onToggleEditMode = (id, setRows, rows) => {
        setRows((state) => {
            return rows.map((row) => {
                if (row.id === id) {
                    return { ...row, isEditMode: !row.isEditMode }
                }
                return row
            })
        })
    }
    function lastBusinessDayOfMonth(year, month) {
        var date = new Date()
        var offset = 0
        var result

        if ('undefined' === typeof year || null === year) {
            year = date.getFullYear()
        }

        if ('undefined' === typeof month || null === month) {
            month = date.getMonth()
        }

        do {
            result = new Date(year, month, offset)

            offset--
        } while (0 === result.getDay() || 6 === result.getDay())

        return result.getDay()
    }

    const getWeeklyHours = (row, value, monthId, isSecondMonth = false) => {
        let firstWeekDays =
            row.week.replace(/[^0-9]/g, '') > 5
                ? 5
                : row.week.replace(/[^0-9]/g, '')
        let hoursFirstWeekForSecondMonth = isSecondMonth
            ? value * firstWeekDays
            : value
        let hoursPerDay = hoursFirstWeekForSecondMonth / firstWeekDays

        let hoursPerWeek = Math.round(hoursPerDay * 5)

        let lastDays = 0
        if (monthId == 0) {
            lastDays = lastBusinessDayOfMonth(
                parseInt(projectionState.projectionDropDownItems.year.id),
                parseInt(projectionState.projectionDropDownItems.month.id) + 1
            )
        } else {
            lastDays = lastBusinessDayOfMonth(
                parseInt(projectionState.projectionDropDownItems.year.id),
                parseInt(projectionState.projectionDropDownItems.month.id)
            )
        }
        let lastWeekHours = Math.round(lastDays * hoursPerDay)

        return {
            hoursPerDay: hoursPerDay,
            hoursPerWeek: hoursPerWeek,
            lastWeekHours: lastWeekHours,
            hoursFirstWeekForSecondMonth: hoursFirstWeekForSecondMonth,
        }
    }
    const fillSecondMonthHours = (name, hours) => {
        let weeklyHours = getWeeklyHours(rows2[0], hours, rows2[0].id, true)
        const newRows = rows2.map((row) => {
            if (row.id === rows2[0].id) {
                return {
                    ...row,
                    [name]: weeklyHours.hoursFirstWeekForSecondMonth,
                }
            } else if (row.id === rows2[rows2.length - 1].id) {
                return { ...row, [name]: weeklyHours.lastWeekHours }
            } else {
                return { ...row, [name]: weeklyHours.hoursPerWeek }
            }
        })
        setRows2(newRows)
    }
    const onChange = (e, row, rows, setRows) => {
        let value = e.target.value < 0 ? (e.target.value = 0) : e.target.value
        if (value.length > 4) {
            value = value.substr(0, 4)
        }
        if (!previous[row.id]) {
            setPrevious((state) => ({ ...state, [row.id]: row }))
        }
        let weeklyHours = getWeeklyHours(row, value, rows[0].id)
        const name = e.target.name
        const { id } = row

        if (row.id == rows[0].id && row.id != rows2[0].id) {
            const newRows = rows.map((row) => {
                if (row.id === id) {
                    return { ...row, [name]: value }
                } else if (row.id === rows[rows.length - 1].id) {
                    return { ...row, [name]: weeklyHours.lastWeekHours }
                } else {
                    return { ...row, [name]: weeklyHours.hoursPerWeek }
                }
            })
            setRows(newRows)
            fillSecondMonthHours(name, weeklyHours.hoursPerDay)
        } else {
            const newRows = rows.map((row) => {
                if (row.id === id) {
                    return { ...row, [name]: value }
                }
                return row
            })
            setRows(newRows)
        }
    }

    const onRevert = (id, rows, setRows) => {
        const newRows = rows.map((row) => {
            if (row.id === id) {
                return previous[id] ? previous[id] : row
            }
            return row
        })
        setRows(newRows)
        setPrevious((state) => {
            delete state[id]
            return state
        })
        onToggleEditMode(id, setRows, rows)
    }

    const setDropDownValue = (type: string, value: any) => {
        if (type === 'projectId') {
            const dropDownValue = {
                ...projectionState,
                projectionDropDownItems: {
                    ...projectionState.projectionDropDownItems,
                    projectId: {
                        id: value.id,
                        name: value.name,
                    },
                },
            }
            setProjectionState(dropDownValue)
        } else if (type === 'projectOwnerId') {
            const dropDownValue = {
                ...projectionState,
                projectionDropDownItems: {
                    ...projectionState.projectionDropDownItems,
                    projectOwnerId: {
                        id: value.pkId,
                        name: value.firstName,
                    },
                },
            }
            setProjectionState(dropDownValue)
        } else if (type === 'year') {
            const dropDownValue = {
                ...projectionState,
                projectionDropDownItems: {
                    ...projectionState.projectionDropDownItems,
                    year: {
                        id: value,
                        name: value,
                    },
                },
            }
            setProjectionState(dropDownValue)
        } else if (type === 'month') {
            const dropDownValue = {
                ...projectionState,
                projectionDropDownItems: {
                    ...projectionState.projectionDropDownItems,
                    month: {
                        id: value.id,
                        name: value.name,
                    },
                },
            }
            setProjectionState(dropDownValue)
            setMonthSingle({
                ...monthSingle,
                firstMonth: value.firstMonth,
                secondmonth: value.secondmonth,
            })
        }
    }
    return (
        <div>
            <div className="right-holder">
                <Nav.Item id="button-nav-project">
                    <Row>
                        <Col>
                            {
                                <Typeahead
                                    id="basic-example"
                                    labelKey="name"
                                    options={projects}
                                    placeholder="Choose a Project..."
                                    selected={projectSelected}
                                    onChange={setProjectSelected}
                                    style={{ width: '300px' }}
                                />
                            }
                        </Col>
                        <Col>
                            {
                                <Typeahead
                                    id="dropdown-variants-secondary-po"
                                    labelKey={(option: ProjectOwnerType) =>
                                        `${option.firstName} ${option.lastName} ${option.employeeID}`
                                    }
                                    options={projectOwners}
                                    placeholder="Choose a PO..."
                                    selected={projectOwnerSelected}
                                    onChange={setProjectOwnerSelected}
                                    style={{ width: '200px' }}
                                />
                            }
                        </Col>
                        <Col>
                            {
                                <DropdownButton
                                    className={`align-dropdown 
                                        assign-title-center dropdown-font`}
                                    key={'secondary'}
                                    id={`dropdown-variants-secondary-po`}
                                    variant={'secondary'}
                                    title={
                                        projectionState.projectionDropDownItems
                                            .month.name
                                    }
                                >
                                    {monthPairs.map((data: any, index) => {
                                        return (
                                            <Dropdown.Item
                                                key={index}
                                                eventKey={`${index + 1}`}
                                                onClick={() =>
                                                    setDropDownValue(
                                                        'month',
                                                        data
                                                    )
                                                }
                                            >
                                                {data.name}
                                            </Dropdown.Item>
                                        )
                                    })}
                                </DropdownButton>
                            }
                        </Col>
                        <Col>
                            {
                                <DropdownButton
                                    className={`align-dropdown
                                        assign-title-center dropdown-font`}
                                    key={'secondary'}
                                    id={`dropdown-variants-secondary-po`}
                                    variant={'secondary'}
                                    title={
                                        projectionState.projectionDropDownItems
                                            .year.name
                                    }
                                >
                                    {years.map((data, index) => {
                                        return (
                                            <Dropdown.Item
                                                key={index}
                                                eventKey={`${index + 1}`}
                                                // onBlur={() => this.handleOnBlur('Visibility')}
                                                onClick={() =>
                                                    setDropDownValue(
                                                        'year',
                                                        data
                                                    )
                                                }
                                            >
                                                {data}
                                            </Dropdown.Item>
                                        )
                                    })}
                                </DropdownButton>
                            }
                        </Col>
                        <Col>
                            <ButtonComponent
                                style={{
                                    fontSize: '14px',
                                    width: '80px',
                                    marginRight: '5px',
                                }}
                                name={ButtonName.SAVE}
                                disabled={setButtonStatus()}
                                onClick={() => postProjectionData()}
                            />
                        </Col>
                    </Row>
                </Nav.Item>
            </div>
            {showMsg && <span style={{ marginLeft: '30px' }}> {message}</span>}
            <Row>
                <Col>
                    <Paper className={classes.root}>
                        <Table
                            className={classes.table}
                            aria-label="caption table"
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left" />
                                    <TableCell
                                        align="left"
                                        style={{ fontWeight: 'bold' }}
                                    >
                                        Weeks{' '}
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        style={{ fontWeight: 'bold' }}
                                    >
                                        Noida ({monthSingle.firstMonth})
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        style={{ fontWeight: 'bold' }}
                                    >
                                        YVR ({monthSingle.firstMonth})
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row) => (
                                    <TableRow key={row.id}>
                                        <TableCell
                                            className={classes.selectTableCell}
                                        ></TableCell>
                                        <CustomTableCell
                                            {...{
                                                row,
                                                name: 'week',
                                                onChange,
                                                rows,
                                                setRows,
                                            }}
                                        />
                                        <CustomTableCell
                                            {...{
                                                row,
                                                name:
                                                    'projectionLocationNoidaHours',
                                                onChange,
                                                rows,
                                                setRows,
                                            }}
                                        />
                                        <CustomTableCell
                                            {...{
                                                row,
                                                name:
                                                    'projectionLocationYVRHours',
                                                onChange,
                                                rows,
                                                setRows,
                                            }}
                                        />
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Paper>
                </Col>
                <Col>
                    <Paper className={classes.root}>
                        <Table
                            className={classes.table}
                            aria-label="caption table"
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left" />
                                    <TableCell
                                        align="left"
                                        style={{ fontWeight: 'bold' }}
                                    >
                                        Weeks{' '}
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        style={{ fontWeight: 'bold' }}
                                    >
                                        Noida ({monthSingle.secondmonth})
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        style={{ fontWeight: 'bold' }}
                                    >
                                        YVR ({monthSingle.secondmonth})
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows2.map((row) => (
                                    <TableRow key={row.id}>
                                        <TableCell
                                            className={classes.selectTableCell}
                                        ></TableCell>
                                        <CustomTableCell2
                                            {...{
                                                row,
                                                name: 'week',
                                                onChange,
                                                rows2,
                                                setRows2,
                                            }}
                                        />
                                        <CustomTableCell2
                                            {...{
                                                row,
                                                name:
                                                    'projectionLocationNoidaHours',
                                                onChange,
                                                rows2,
                                                setRows2,
                                            }}
                                        />
                                        <CustomTableCell2
                                            {...{
                                                row,
                                                name:
                                                    'projectionLocationYVRHours',
                                                onChange,
                                                rows2,
                                                setRows2,
                                            }}
                                        />
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Paper>
                </Col>
            </Row>
        </div>
    )
}
