export class TemplateForm {
    templateTypeId: number;
    workItemTemplate: WorkItemTemplate;
    constructor() {
        this.workItemTemplate = new WorkItemTemplate();
        this.workItemTemplate.workItem = new WorkItem();
        this.workItemTemplate.category = Object.assign({}, {
            id: 0,
            name: 'Category*'
        });
        this.workItemTemplate.subCategory = Object.assign({}, {
            id: 0,
            name: 'Sub Category*'
        });
    }
  };

export class WorkItemTemplate {
    categoryId: number;
    createdBy: number;
    name: string = '';
    templateTypeId: number;
    statusId: number;
    subcategoryId: number;
    updatedBy: number;
    workItem: WorkItem;
    workItemTypeId: number;
    category: {
        id: number,
        name: string
    };
    subCategory: {
        id: number,
        name: string
    }
}


export class WorkItem {
    assigneeName?: string | null;
    childWorkItems: WorkItem[];
    createdBy: number;
    description: string | null;
    dueDate: string | null;
    endDate?: string | null | Date = new Date();
    id?: 1;
    isAssigneeNotified?: boolean;
    isTemplate?: boolean;
    guid?: string;
    parentWorkItemGuid?: string | null;
    parentWorkItemId?: number | null;
    name: string;
    projectId?: string | null;
    startDate: string;
    templateId?: number | null;
    updatedAt?: string;
    updatedBy?: number;
    userId?: number | null;
    watchers?: any[];
    workItemPriorityId: number = 2;
    workItemVisibilityId: number = 2;
    workItemStatusId: number = 5;
    workItemTypeId: number;

    constructor() {
        this.childWorkItems = [];
        this.watchers = [];
    }
}