import { UIState } from '../Interfaces/UIStateInterface';
import {ActiveSideNavEnum} from '../Enum/ActiveSideNav.enum';
import  { UIActionConstants } from '../actions/Constants';

const initialState: UIState = {
    activeRouteId: 0,
    headerHeading: 'Home',
    modalState: false,
    modalResolve: undefined,
    isLoading: false,
    isApiResponseFlag: false
};

export function UIStateReducer(state: UIState = initialState,
action: any): UIState {
    if(action.type === UIActionConstants.SET_ACTIVE_ROUTE_ID) {
    switch (action.data) {
        case ActiveSideNavEnum.APPLICATIONS:
            return {
                ...state,
                activeRouteId: ActiveSideNavEnum.APPLICATIONS
            }
            break;
        case ActiveSideNavEnum.DASHBOARD:
            return {
                ...state,
                activeRouteId: ActiveSideNavEnum.DASHBOARD
            }
            break;
        case ActiveSideNavEnum.ANNOUNCEMENT:
            return {
                ...state,
                activeRouteId: ActiveSideNavEnum.ANNOUNCEMENT
            }
            break;
        case ActiveSideNavEnum.REPORTS:
            return {
                ...state,
                activeRouteId: ActiveSideNavEnum.REPORTS
            }
            break;
        default:
            return initialState;
    }
    } else if(action.type === UIActionConstants.HEADER_HEADING) {
        return {
            ...state,
            headerHeading: action.data
        }
    } else if(action.type === UIActionConstants.MODAL_STATE) {
        return {
            ...state,
            modalState: action.data
        }
    } else if(action.type === UIActionConstants.MODAL_FUNCTION) {
        return {
            ...state,
            modalResolve: action.data
        }
    } else if(action.type === UIActionConstants.IS_LOADING) {
        return {
            ...state,
            isLoading: action.data
        }
    } else if(action.type === UIActionConstants.API_RESPONSE) {
        return {
            ...state,
            isApiResponseFlag: action.data
        }
    } else {
        return state;
    }
}