import { createAction } from '../Utils/createActionType';
import { ITEM, WorkItemList } from './types'
import { WorkItemReducer, WorkItem } from '../Interfaces/WorkItemInterface';

export const  callWorkItemApi = (params?: any, filterParams?:any) => createAction(WorkItemList.CALL_WORKITEM, {params, filterParams });

export const  setWorkItemList = (WorkItem: WorkItem[]) => createAction(WorkItemList.SET_WORKITEM, { WorkItem });

export const  deleteWorkItem = (id: number, guid: string, taskId: number, filterParams?: any) => createAction(WorkItemList.DELETE_WORKITEM, { id, guid, taskId });

export const  setWorkItemPagination = ({currentPage, hasNext, hasPrevious, 
    pageSize, totalCount, totalPages}) => createAction(WorkItemList.WORKITEM_PAGINATION, { 
    currentPage, hasNext, hasPrevious, pageSize, totalCount, totalPages
});
