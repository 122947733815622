import { AuthConfig } from '../Interfaces/AuthConfiginterface'
const { REACT_APP_ENVIRONMENT } = process.env

export const AuthenticationConstants: AuthConfig = {
    CLIENT_ID: '96e03e2b-91b4-49a3-afc2-d24cabb48690',
    TENENT_ID: 'b5db11ac-8f37-4109-a146-5d7a302f5881',
    REDIRECT_URI:
        REACT_APP_ENVIRONMENT === 'development'
            ? 'https://optm-oic-portalui-dev-001-app.azurewebsites.net'
            : REACT_APP_ENVIRONMENT === 'production'
            ? 'https://portal.optimusinfo.com/'
            : REACT_APP_ENVIRONMENT === 'staging'
            ? 'https://optm-oic-portalui-stage-001-app.azurewebsites.net'
            : REACT_APP_ENVIRONMENT === 'test'
            ? 'https://optm-oic-portalui-test-001-app.azurewebsites.net'
            : 'http://localhost:3000',
    AUTHORITY: 'https://login.microsoftonline.com/',
    CACHE_LOCATION: 'localStorage',
    SCOPES: ['User.Read'],
}

export const InterceptorConstants = {
    BEARER: 'Bearer ',
    JWT_TOKEN: 'jwt_token',
    UNAUTHORIZED: 401,
    ACCESS_TOKEN: 'accessToken',
}

export const API_URL =
    REACT_APP_ENVIRONMENT === 'production'
        ? 'https://optm-oic-portalapi-prod-001-app.azurewebsites.net'
        : REACT_APP_ENVIRONMENT === 'test'
        ? 'https://optm-oic-portalapi-test-001-app.azurewebsites.net'
        : REACT_APP_ENVIRONMENT === 'staging'
        ? 'https://optm-oic-portalapi-stage-001-app.azurewebsites.net'
        : 'https://optm-oic-portalapi-dev-001-app.azurewebsites.net'

export const deptNameWithId: Array<any> = [
    {
        supportTicketDepartmentId: 1,
        departmentName: 'YVR Admin And Logistic Support',
        categories: [
            {
                supportTicketCategoryId: 1,
                categoryName: 'General Task',
            },
            {
                supportTicketCategoryId: 2,
                categoryName: 'Cloud Resource Approval Request',
            },
            {
                supportTicketCategoryId: 3,
                categoryName: 'Other Budget Approval Request',
            },
        ],
        priorities: [
            {
                supportTicketPriorityId: 1,
                priorityName: 'None',
            },
            {
                supportTicketPriorityId: 2,
                priorityName: 'High',
            },
            {
                supportTicketPriorityId: 3,
                priorityName: 'Medium',
            },
            {
                supportTicketPriorityId: 4,
                priorityName: 'Low',
            },
        ],
    },
    {
        supportTicketDepartmentId: 2,
        departmentName: 'Optimus Noida IT Support',
        categories: [
            {
                supportTicketCategoryId: 1,
                categoryName: 'General Task',
            },
            {
                supportTicketCategoryId: 2,
                categoryName: 'Cloud Resource Approval Request',
            },
            {
                supportTicketCategoryId: 3,
                categoryName: 'Other Budget Approval Request',
            },
            {
                supportTicketCategoryId: 4,
                categoryName: 'Security Incident',
            },
        ],
        priorities: [
            {
                supportTicketPriorityId: 1,
                priorityName: 'None',
            },
            {
                supportTicketPriorityId: 2,
                priorityName: 'High',
            },
            {
                supportTicketPriorityId: 3,
                priorityName: 'Medium',
            },
            {
                supportTicketPriorityId: 4,
                priorityName: 'Low',
            },
        ],
    },
    {
        supportTicketDepartmentId: 3,
        departmentName: 'Optimus Noida Admin Support',
        categories: [
            {
                supportTicketCategoryId: 1,
                categoryName: 'General Task',
            },
            {
                supportTicketCategoryId: 2,
                categoryName: 'Cloud Resource Approval Request',
            },
            {
                supportTicketCategoryId: 3,
                categoryName: 'Other Budget Approval Request',
            },
            {
                supportTicketCategoryId: 4,
                categoryName: 'Security Incident',
            },
        ],
        priorities: [
            {
                supportTicketPriorityId: 1,
                priorityName: 'None',
            },
            {
                supportTicketPriorityId: 2,
                priorityName: 'High',
            },
            {
                supportTicketPriorityId: 3,
                priorityName: 'Medium',
            },
            {
                supportTicketPriorityId: 4,
                priorityName: 'Low',
            },
        ],
    },
    {
        supportTicketDepartmentId: 4,
        departmentName: 'Azure Client Support',
        categories: null,
        priorities: [
            {
                supportTicketPriorityId: 1,
                priorityName: 'None',
            },
            {
                supportTicketPriorityId: 2,
                priorityName: 'Production - Critical',
            },
            {
                supportTicketPriorityId: 3,
                priorityName: 'Production - Non Critical',
            },
            {
                supportTicketPriorityId: 4,
                priorityName: 'Non Prodution',
            },
        ],
    },
]

export const classWithId = [
    {
        supportTicketClassificationId: 1,
        classificationName: 'None',
    },
    {
        supportTicketClassificationId: 2,
        classificationName: 'Approval',
    },
    {
        supportTicketClassificationId: 3,
        classificationName: 'Question',
    },
    {
        supportTicketClassificationId: 4,
        classificationName: 'Problem',
    },
    {
        supportTicketClassificationId: 5,
        classificationName: 'Feature',
    },
    {
        supportTicketClassificationId: 6,
        classificationName: 'Others',
    },
]

export const supportTicketStatus = [
    {
        supportTicketStatusId: 1,
        displayName: 'Open',
    },
    {
        supportTicketStatusId: 2,
        displayName: 'In Progress',
    },
    {
        supportTicketStatusId: 3,
        displayName: 'Closed',
    },
]